.facebook-box {
    font-size: 10px;
    font-family: Roboto, Lato, Arial, Helvetica, Sans-Serif;
}

.facebook-box p {
    font-size: 1.6rem;
    color: #222;
}

.facebook-box {
    /*background-color: white;*/
    /*box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);*/
    width: 100%;
    height: 10rem;
    border-radius: 0.2rem;
}

.facebook-box h1 {
    font-weight: 800;
    font-size: 1.9rem;
    line-height: 0.7rem;
    margin: 0;
    color: #333;
}

.author {
    color: teal;
    font-size: 1.5rem;
    vertical-align: middle;
    font-weight: 400;
}

.facebook-box h2 {
    font-weight: 400;
    font-size: 1.5rem;
    color: #666;
}

.facebook-box a:hover {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.footer-text {
    text-align: center;
    color: #AAA;
    font-size: 1.4rem;
}

.fa-heart {
    font-size: 1.2rem;
}

.button-container {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
}

.button-name {
    text-align: center;
    margin: 1rem 0 0.4rem 0;
    font-size: 1.6rem;
    font-weight: 600;
}

.button-class {
    text-align: center;
    margin: 0;
    font-size: 1.4rem;
    font-family: Monospace;
}



/* Facebook Login Button Styles */
.facebook-login {
    padding: 8px 10px;
    background-color: #4267b2;
    color: #FFFFFF;
    border-radius: 4px;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: .25px;
    text-decoration: none;
    font-size: 1.0rem;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}



/*a:hover {*/
    /*text-decoration: none;*/
/*}*/

.facebook-login-text {
    padding: 0 14px;
    text-decoration: none;
}

.facebook-icon>svg {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

.facebook-login:hover {
    background-color: #365899;
    transition-property: background-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

.facebook-login:active {
    background-color: #577fbc;
    transition-duration: 40ms;
    transition-property: background-color, box-shadow;
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
}
