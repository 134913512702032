.MainVideoRoomPage {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
}

.MainVideoRoomPage__videoRailContainer {
    position:relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 70%;
}

.MainVideoRoomPage__leftRailContainer {
    flex: 1 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
}

.MainVideoRoomPage__rightRailContainer {
    flex: 1 100%;
    display: none;
    flex-direction: column;
}

/* This class hides one of the ChatViewerBoxContainer so only one will appear depending on the screen size */
.MainVideoRoomPage__rightRailContainerChatViewerBoxContainer {
    display: none;
}

.MainVideoRoomPage_chatViewerBoxContainer {
    flex: 1 100%;
}

@media all and (min-width: 600px) {
    /*.MainVideoRoomPage__sidePanel {*/
    /*flex: 1 0 0;*/
    /*}*/
}

@media all and (min-width: 800px) {
    .MainVideoRoomPage__sidePanel {
        flex: 1 0 0;
    }

    .MainVideoRoomPage__videoRailContainer {
        flex: 3 0 0;
    }

    .MainVideoRoomPage__leftRailContainer {
        order: 1;
    }

    .MainVideoRoomPage__videoRailContainer {
        order: 2;
    }

    .MainVideoRoomPage__rightRailContainer {
        order: 3;
        display: flex;
    }

    /* This class displays one of the ChatViewerBoxContainer so only one will appear depending on the screen size */
    .MainVideoRoomPage__rightRailContainerChatViewerBoxContainer {
        display: inherit;
        flex: 1;
    }

    /* This class hides one of the ChatViewerBoxContainer so only one will appear depending on the screen size */
    .MainVideoRoomPage_chatViewerBoxContainer {
        display: none;
    }

    .footer {
        order: 4;
    }
}
