@import "../../variables.css";

.BoxBlock__contentBase .chatFieldWrapper .chatInput {
  padding: 12px 42px;
  background: #fff;
  display: block;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--light-grey-color);
  margin-bottom: 20px;
  color: var(--empty-messages-grey-color);
}

.BoxBlock__contentBase .chatFieldWrapper .chatInput::placeholder {
  color: var(--light-grey-color);
}

.BoxBlock__contentBase .chatFieldWrapper .chatInput:focus {
  outline: none;
}

.BoxBlock__contentBase .chatFieldWrapper {
  position: relative;
}

.BoxBlock__contentBase .chatFieldWrapper .chatMessageIcon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: var(--light-grey-color);
  z-index: 1;
}

.BoxBlock__contentBase .chatFieldWrapper .chatSendMessageButton {
  cursor: pointer;
}

.BoxBlock__contentBase .chatFieldWrapper .chatSendMessageButton {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-48%);
  color: var(--chat-blue-color);
  z-index: 1;
}

.progressBar {
  height: 8px;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 42px;
  transform: translateY(-50%);
  width: calc(100% - 86px);
}

.progress {
  background: var(--chat-blue-color);
  height: 100%;
  border-radius: 5px;
}
