@import "../variables.css";

.LiveVideoDynamicToggle {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 10px;
    left: 0;
    top: 0;
    height: 50px;
    z-index: 100;
}

/*.LiveVideoFloatingToggle__floatingToggle {*/
/*}*/


.strip-button {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    width: 70px;
    padding: 4px;
    background-color: white;
    transition: 0.25s;
    color: rgba(45, 45, 45, 0.58);
    height: 100%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 2px solid rgba(45, 45, 45, 0.58);
    border-radius: 2px;
}

.strip-button-0 {
    border-style: solid;
    border-color: var(--buttons-cancel);
    border-width: 2px 0 2px 2px;
    border-radius: 2px 0 0 2px;
}

.strip-button-1 {
    border-style: solid;
    border-color: var(--buttons-cancel);
    border-width: 2px 2px 2px 0;
    border-radius: 0 2px 2px 0;
}

.active-strip-button[disabled] {
    background: white;
    color: #e04dcf;
    transition: 0.2s;
    border-color: #e04dcf;
}

.strip-button-0::after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: -1px;
    width: 2px;
    height: calc(100% + 4px);
    background: #e04dcf;
}

.strip-button-1::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -1px;
    width: 2px;
    height: calc(100% + 4px);
    background: #e04dcf;
}