.VideoTotals__iconWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    color: #fff;
    background-color: rgba(45, 45, 45, 0.58);
    border-radius: 6px;
}

.VideoTotals__iconWrapper:nth-child(1n+2) {
  margin-left: 6px;
}

.VideoTotals__value {
  margin-left: 7px;
  font-size: 13px;
  font-weight: 600;
}
