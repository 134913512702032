@import "../../variables.css";

.ChatMessage {
    transform: translateY(-20px);
    animation: ani-ChatMessage__fadeIn 0.5s;
    animation-fill-mode: forwards;
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    align-items: flex-start;
}

.ChatMessage__messageSection {
    padding-left: 20px;
    word-break: break-all;
    word-wrap: break-word;
    font-weight: var(--chat-message-font-weight);
    font-size: var(--chat-message-font-size);
    line-height: normal;
}

.ChatMessage__messageSection--text {
  padding: 6px 10px;
  background-color: var(--chat-grey-color);
  border-radius: 14px;
  color: var(--users-text-color);
}

.ChatMessage__messageSection--gift {
  color: var(--new-gift-indication-color);
  font-weight: var(--chat-message-gift-font-weight);
  font-style: italic;
}

.ChatMessage__messageSection--follow {
  color: var(--new-follower-indication-color);
  font-weight: var(--chat-message-follow-font-weight);
  font-style: italic;
}

.ChatMessage__messageSection--join {
  color: var(--chat-blue-color);
}

.ChatMessage__giftImage {
  height: 42px;
}

@keyframes ani-ChatMessage__fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
