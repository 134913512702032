.ComingSoon-wrap {
  position: relative;
}

@keyframes comingSoonFading {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.ComingSoon {
  position: absolute;
  display: none;
  left: -10px;
  min-width: calc(100% + 20px);
  transform: rotate(15deg);
  padding: 0 5px;
  font-size: 8px;
  font-weight: 600;
  white-space: pre;
  text-align: center;
  text-transform: uppercase;
  border: 0px solid #fff;
  border-top-width: 2px;
  border-bottom-width: 2px;
  background-color: #fff;
  opacity: 1;
}

.ComingSoon.active {
  opacity: 1;
  display: block;
}

.ComingSoon.fading {
  display: block;
  opacity: 0;
  animation-name: comingSoonFading;
  animation-duration: 4s;
}
