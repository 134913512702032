@import "../variables.css";

.GoogleButton__Button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    cursor: pointer;
    background-color: white;
    padding: 30px;
    color: var(--welcome-page-text-color-young);
    font-size: 16px;
    width: 265px;
    background: url(../assets/images/signInWithGoogle.svg) center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
/* 
.GoogleButton__GoogleConnectIcon {

} */

.GoogleButton__Text {
    /* padding-left: 30%; */
    margin-left: 28%;
    color: white;
}