@import "../variables.css";

.SystemMessage {
    z-index: 2000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--system-message-height);
    width: 100%;
    margin: var(--card-margin);
    padding: var(--card-padding);
    border-radius: 10px;
}

/* Message types */

.SystemMessage--default {
    color: #fff;
    background-color: var(--action-bar-icon-color);
}
