.ChatMessageList {

}

.ChatMessageOverlay {
    position: fixed;
    color: white;
    padding: 10px;
    bottom: 0;
    max-height: 400px;
    overflow-y: scroll;
}

.ChatMessageOverlay .UserBox__userName {
    color: white;
}
