@import "../variables.css";

.LoolaAppHeader {
    height: var(--app-header-height);
    background-color: var(--app-header-background-color);
    margin-bottom: 2px;
    padding: 10px;
    padding-left: 0;
    border-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;

    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.06);
}

.LoolaAppHeader.blueHeader {
  background-color: #2bb6f9;
}

.LoolaAppHeader.blueHeader .LoolaAppHeader__Link {
  color: #fff; 
}

.LoolaAppHeader__headerItemText--NewIndication {
    font-size: 0.65em;
    color: #fe2c55;
    vertical-align: top;
}

.LoolaAppHeader.blueHeader .LoolaAppHeader__headerItemText--Text::before {
  background-color: #fff;
}

.LoolaAppHeader.blueHeader .UserBox__userName {
  color: #fff;
}

.LoolaAppHeader.blueHeader .LoolaAppHeader__headerItemText--Text a span {
  color: #fff;
}

/** ITEMS \ LI in the app-header**/
.LoolaAppHeader__headerItems {
    display: flex;
    align-items: center;
    list-style-type: none;
}

.LoolaAppHeader__headerItemsItem {
    cursor: pointer;
    color: var(--app-header-text-color);
    margin-right: var(--gutter);
}

.LoolaAppHeader__Link {
    display: flex;
    position: relative;
    font-size: 11px;
    color: #868484;
}

.LoolaAppHeader__Link:hover {
  color: #4e4949;
  text-decoration: none;
}

.LoolaAppHeader__headerItemText--Text {
  position: relative;
  padding-right: 3px;
  padding-top: 3px;
}

.LoolaAppHeader__headerItems .LoolaAppHeader__headerItemText--Text {
  display: none;
}

.LoolaAppHeader__headerItemsItem.LoolaAppHeader__headerItemText--Text::before {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -12px;
  background-color: var(--golden-yellow-color);
  box-shadow: 0 -1px 5px 0 rgba(255, 206, 0, 0.63);
}

.LoolaAppHeader__headerItemsItem.LoolaAppHeader__headerItemText--Text:hover::before {
  content: "";
}

/** LOGO RELATED **/
.LoolaAppHeader__headerItemsItem--Logo {
  margin-right: 10px;
}

.LoolaAppHeader__LoolaLogo{
    height: 25px;
}

.LoolaAppHeader__headerItemText--Logo {
    font-size: var(--large-font-size);
    color: var(--app-header-logo-text-color)
}


/** TEXT OM HEADER ITEMS **/
.LoolaAppHeader__headerItemText {
    display: none;
}

@media all and (min-width: 300px) {
  .LoolaAppHeader {
    padding: 10px 27px;
  }

  .LoolaAppHeader__headerItemsItem--Logo {
    margin-right: calc(var(--gutter) * 2);
  }

  .LoolaAppHeader__headerItemText {
      display: inline;
      margin-left: calc(var(--gutter) / 3);
  }

  .LoolaAppHeader__headerItems .LoolaAppHeader__headerItemText--Text {
    display: block;
  }
}

@media all and (min-width: 770px) {
  .LoolaAppHeader__Link {
    font-size: 16px;
  }
}

@media all and (max-width: 426px) {
  .LoolaAppHeader__headerItems .LoolaAppHeader__headerItemText--Text {
    display: none;
  }
}