.UserBox {
  display: flex;
  margin-bottom: 5px;
}

.UserBox .PlatformIcon__channelStyle {
    margin-right: 5px;
}

.UserBox:last-child {
  margin-bottom: 0;
}

.UserBox__imageStyle {
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 11px;
}

.UserBox__imageSize--small {
    width: 22px;
    height: 22px;
}

.UserBox__imageSize--medium {
    width: 32px;
    height: 32px;
}

.UserBox__imageSize--large {
    width: 43px;
    height: 43px;
}


.UserBox__userName {
    word-break: break-all;
    display: inline;
    font-size: var(--user-box-name-font-size);
    color: var(--users-text-color);
}


.UserBox__userName--normal {
    font-weight: 400;
}

.UserBox__userName--bold {
    font-weight: 600;
}

/* user box in header has the same class, as user box in chat... */
.PresenceIndicator .UserBox {
  align-items: center;
  flex-direction: row;
}

.PresenceIndicator .UserBox__imageStyle {
  border-radius: 50%;
  margin-right: 12px;
}
