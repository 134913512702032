
.VideoLink {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  transition:all .25s ease-in-out
}

.VideoLink:nth-child(2){
  margin-left: 10px;
}

.VideoLink:hover {
  color: var(--weird-green-color);
}
/*the tip*/
.VideoLink:before {
  content: attr(data-tip);
  font-size: 14px;
  position: absolute;
  background:var( --empty-messages-grey-color);
  color: var(--box-base-color);
  line-height: 1.2em;
  padding: 4px 5px;
  font-style: normal;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  min-width: 120px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;
  font-family: var(--basic-text-font-family);
  letter-spacing: 0;
  border: 1px solid var(--box-base-color);
}

.VideoLink:after {
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out
}

.VideoLink:hover:before,
.VideoLink:hover:after {
  visibility: visible;
  opacity: 1
}
.VideoLink.left:before {
  left: 0;
  top: 50%;
  transform: translate(calc(-100% - 8px), -50%);
  box-sizing: border-box;
  border-radius: 6px;
}
.VideoLink.left:after {
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent rgba(140, 140, 140, 0.85);
  left: -9px;
  top: 50%;
  transform: translate(0, -50%);
}