@import "../variables.css";

.LiveVideoInformationOverlay {
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}
