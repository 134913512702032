@import "../../variables.css";

.SinglePlatformSettingsPage {
    display: flex;
    flex-direction: column;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 3px;
    border-radius: 8px;
}

.SinglePlatformSettingsPage__title {
    margin-top: 0;
    margin-bottom: 13px;
    color: var(--users-text-color);
    font-size: 15px;
    font-weight: 600;
    font-family: var(--basic-text-font-family);
}

.SinglePlatformSettingsPage__section {
    width: 100%;
    margin-bottom: 13px;
}
