@import "../variables.css";

.StreamDestinationBox {
  margin: 0;
  padding: 0 10px;
}

.StreamDestinationBox_title {
    display: flex;
    align-items: center;
}

.StreamDestinationBox_titleText {
    margin-right: calc(var(--gutter) / 2);
}

.StreamDestinationBox_destinations {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media all and (max-width: 600px) {
    .StreamDestinationBox {
        position: absolute;
        z-index: 101;
        width: 100%;
        left: 0;
        margin-left: 0;
        border-radius: 0px;
        border-bottom: 2px solid var(--weird-green-color);
    }
}
