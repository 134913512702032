body {
    background: #fff;
}

.LandingPage{
    overflow-x: hidden;
}

.top-section {
    width: 100%;
    background: url(../../assets/images/background1.png);
    background-size: cover;
}

.getStarted {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    box-shadow: 0px 100px 100px -100px rgba(0, 0, 0, 0.12);
    z-index: 2;
    padding-bottom: 100px;
}

.startedBox {
    max-width: 520px;
    width: 100%;
    position: relative;
    left: 80px;
}

.title {
    font-family: Nunito;
    font-size: 40px;
    font-weight: 800;
    line-height: 1.28;
    color: #ffffff;
}

.description {
    font-family: Nunito;
    font-size: 22px;
    color: #ffffff;
    margin: 20px auto;
}

.btn-box {
    width: 272px;
}

.btnStart {
    width: 272px;
    height: 77px;
    border-radius: 10px;
    box-shadow: 0 4px 21px 0 rgba(0, 0, 0, 0.18);
    font-family: Nunito;
    font-size: 25px;
    font-weight: bold;
    font-style: normal;
    border: none;
    color: #fff;
    cursor: pointer;
    background-image: linear-gradient(to bottom, #fdbd02, #ffa05f);
}

.btnStart:hover { 
    background-image: radial-gradient(circle at 49% 50%, #f4bb37, #f4ba25 56%, #e9b323 59%, #ebb417 81%, #fdbd02 82%, #ecb71c, #fdbd02);
    box-shadow: 0 5px 21px 0 rgba(0, 0, 0, 0.18);
}

.btnStart:active { 
    background-image: radial-gradient(circle at 49% 50%, #f4bb37, #f4ba25 46%, #e9b323 49%, #ebb417 71%, #fdbd02 72%, #ecb71c, #fdbd02);
}

.free {
    font-family: Nunito;
    font-size: 23px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    display: block;
    margin-top: 10px;
}

.slogan-top {
    text-align: center;
    color: #8a8a8a;
    font-weight: bold;
    font-family: Nunito;
    font-size: 30px;
}

.slogan-top span {
    color: #f62682;
}

.slogan-bottom {
    text-align: center;
    font-family: Nunito;
    font-size: 40px;
    font-weight: bold;
    color: #000;
}

#preview-container-box {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    
}

.preview-box {
    position: relative;
    max-width: 652px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box-title {
    font-family: Nunito;
    font-size: 28px;
    font-weight: bold;
}

#preview-container-box > .preview-box:nth-child(1) {
    bottom: -120px;
}

#preview-container-box > .preview-box:nth-child(2) {
    bottom: 18px;
}

#preview-container-box > .preview-box:nth-child(3) {
    top: -106px;
}

#preview-container-box > .preview-box:nth-child(4) {
    top: -236px;    
}

#preview-container-box > .preview-box:nth-child(1) .preview-container .box-title {
    color: #f62682;
}

#preview-container-box > .preview-box:nth-child(2) .preview-container .box-title {
    color: #febf00;
}

#preview-container-box > .preview-box:nth-child(3) .preview-container .box-title {
    color: #2bb8eb;
}

#preview-container-box > .preview-box:nth-child(4) .preview-container .box-title {
    color: #1ec978;
}

#preview-container-box > .preview-box:nth-child(1) .preview-container .box-line {
    background: #f62682;
}

#preview-container-box > .preview-box:nth-child(2) .preview-container .box-line {
    background: #febf00;
}

#preview-container-box > .preview-box:nth-child(3) .preview-container .box-line {
    background: #2bb8eb;
}

#preview-container-box > .preview-box:nth-child(4) .preview-container .box-line {
    background: #1ec978;
}

.box-line {
    width: 126px;
    height: 6px;
    border-radius: 3px;
    display: block;
    margin-top: 22px;
}

.preview-box.rightFloat .preview-container .box-line {
    margin-left: auto
}

.box-hint {
    font-family: Nunito;
    font-size: 17px;
    color: #000000;
}

.preview-box.rightFloat {
    margin-left: auto;
}

.preview-box.rightFloat img {
    order: 2
}

.preview-box.rightFloat .preview-container {
    text-align: right;
}

.line-background {
    background: url(../../assets/images/line_back.svg) no-repeat center;
    background-size: cover;
    background-position: center;
    padding: 7%;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    top: -45px;
    background-position-y: -5rem;
}

.line-background p {
    font-family: Nunito;
    font-size: 30px;
    font-weight: bold;
    color: #4a4a4a;
}

.line-background button {
    max-width: 518px;
    width: 100%;
    height: 73px;
    border-radius: 10px;
    font-family: Nunito;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: #febf00;
    border: none;
    margin-top: 10px;
    cursor: pointer;
}

.line-background button:hover { 
    background-color: #29b7f1;
}

.line-background button:active { 
    background-color: #29b7f1;
}

.navigation-container-box {
    max-width: 880px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 95px;
}

.navigation-box {
    max-width: 250px;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #ededed;
    padding: 37px;
    box-sizing: border-box;
    padding: 37px;
}

.navigation-img {
    width: 120px;
    height: 120px;
    background-color: #f32d8f;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;
}

.navigation-title {
    font-size: 20px;
    color: #000000;
    text-align: center;
    font-family: Nunito;
    font-weight: bold;
    margin: 25px 0;
}

.navigation-link {
    border-radius: 7px;
    background-color: #f32d8f;
    max-width: 180px;
    width: 100%;
    color: #fff;
    padding: 12px 0;
    text-align: center;
    display: block;
    font-size: 20px;
}

.navigation-container-box > .navigation-box:nth-child(1) .navigation-link:hover {
    box-shadow: inset 0 1px 9px 0 rgba(0, 0, 0, 0.34);
    background-color: #e065a2;
}

.navigation-container-box > .navigation-box:nth-child(1) .navigation-img img {
    position: relative;
    left: 7%;
}

.navigation-container-box > .navigation-box:nth-child(2) .navigation-img {
    background: #fbb002;
}

.navigation-container-box > .navigation-box:nth-child(2) .navigation-link {
    background: #fbb002;
}

.navigation-container-box > .navigation-box:nth-child(2) .navigation-link:hover {
    box-shadow: inset 0 1px 9px 0 rgba(0, 0, 0, 0.34);
    background-color: #ebac19;
}

.navigation-container-box > .navigation-box:nth-child(2) .navigation-title {
    width: 140px;
    margin: 25px auto;
}

.navigation-container-box > .navigation-box:nth-child(3) .navigation-img {
    background: #20c77e;
}

.navigation-container-box > .navigation-box:nth-child(3) .navigation-link {
    background: #20c77e;
}

.navigation-container-box > .navigation-box:nth-child(3) .navigation-link:hover {
    box-shadow: inset 0 1px 9px 0 rgba(0, 0, 0, 0.34);
    background-color: #2cac74;
}

.navigation-container-box .navigation-box .navigation-link {
    text-decoration: none;
    color: #fff;
}

.videoText {
    text-align: center;
    font-size: 40px;
    color: #000;
    font-weight: bold;
}

.videoText span{
    color: #f62682;
}

.containerVideoBox {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}

.containerVideoBox iframe, .containerVideoBox object, .containerVideoBox embed {
	position:absolute;
	width:100%;
    height:100%; 
}

#videoBox {
    position: relative;
    padding-bottom: 45%;
    margin-left: 10%;
    margin-right: 10%;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.12);
    background: url(../../assets/images/whatIsLoola.jpg) center;
    background-repeat: no-repeat;
    background-size: 100% 100%; 
}

.videoClickButton{
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 40%; 
    background:  url(../../assets/images/play-button.svg) no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow:hidden;
    background-repeat: no-repeat;
    width:88px;
    height:88px;
    cursor:pointer;
}

.react-parallax {
    height: 500px;
}

.parallaxBox {
    position: absolute;
    bottom: 0;
    top: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
}