.CopyToClipboardComponent {
    display: flex;
    flex-flow: column;
    justify-content: center;
    /*padding: 5px;*/

    background-color: #fff;
    color: #222;
    position: relative;
    /*border: 1px solid #eee;*/
    overflow: hidden;
    cursor: pointer;
    padding: 10px;
    width: 400px;

}

.CopyToClipboardComponent .TextField__inputFormInputLabel {
    background-color: #fff;
}

.CopyToClipboardComponent .TextField__inputFormInput {
    cursor: pointer;
}

.pre--input {
    width:100%;
}

.wrapper {
    color: #fff;
    width: 50%;
    margin: 20px 25%;
}
p {
    font-weight: 700;
}
/*.code-sample {*/
    /*background-color: #fff;*/
    /*color: #222;*/
    /*position: relative;*/
    /*!*border: 1px solid #eee;*!*/
    /*overflow: hidden;*/
    /*cursor: pointer;*/
    /*padding: 10px;*/
/*}*/
.CopyToClipboardComponent:hover {
     opacity: 1;
}

.CopyToClipboardComponent:hover .clip-button{
    opacity: 1;
}

.CopyToClipboardComponent.clip-pop .clip-button {
    opacity: 0;
    animation: 0.3s clip-pop 0.1s;
}

.CopyToClipboardComponent pre {
    padding: 20px;
    margin: 0;
}
.CopyToClipboardComponent .clip-button {
    position: absolute;
    opacity: 0;
    right: 15px;
    top: 15px;
    padding: 2px 4px;
    border: 1px solid #eee;
    border-radius: 10px;
    font-size: 1rem;
    color: #fff;
    background: #444;
    transition: all 0.2s ease;
}


@keyframes clip-pop {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    80% {
        transform: scale(1.5);
        opacity: 0.2;
    }
    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}
