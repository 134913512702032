.LandingPageFooter {

}

.LandingPageFooter .logoFooter {
    width: 143px;
    height: 79px;
    margin: 0 auto;
    display: block;
    padding: 10px;
}

.LandingPageFooter .iconsFooter {
    padding: 10px;
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.LandingPageFooter .textFooter {
    padding: 10px;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.LandingPageFooter .textFooter a:hover {
    color: #157ab5;
}

.LandingPageFooter .textFooterSection {
    padding-right: 10px;
}

.LandingPageFooter .TermsAndPrivacyWrapper{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}