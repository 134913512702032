.circle{
  width: 37px;
  height: 37px;
  opacity: 0.46;
  background-color: #cecece;
  border-radius: 50%;
}

.oval{
  width: 137px;
  height: 7px;
  opacity: 0.46;
  border-radius: 3.5px;
  background-color: #cecece;
  margin: 5px;
}

.oval-second{
  width: 55px;
  height: 7px;
  opacity: 0.46;
  border-radius: 3.5px;
  background-color: #cecece;
  margin: 5px;
}