.confirmation-modal{
    width: 300px;
    height: 300px;
}

.confirmation-modal__action-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.confirmation-modal__action--hide{
    position: absolute;
    right: 5px;
    top: 5px;
}
.confirmation-modal__action--yes{
    margin: 0 30px 0 0;
    width: auto;
    min-width: 90px;
}

.confirmation-modal__action--no{
    border-radius: 9px;
    width: auto;
    min-width: 90px;
}

.confirmation-modal__wrapper{
   margin: auto;
}