.Spinner {

}

/* ================================= */
/* Default Spinner */
/* ================================= */
.Spinner--default {
    border-radius: 50%;
    animation: ani-spinner-default 1.5s linear infinite;
    border-color: var(--spinner-default-spinner-bg-color);
    border-top-color: var(--spinner-default-spinner-color);
    border-left-color: var(--spinner-default-spinner-color);
    border-style: solid;
}

/* Default spinner sizes */
.Spinner--default--xs {
    border-width: 1px;
    width: 15px;
    height: 15px;
}

.Spinner--default--small {
    border-width: 2px;
    width: 20px;
    height: 20px;
}

.Spinner--default--medium {
    border-width: 3px;
    width: 40px;
    height: 40px;
}

.Spinner--default--large {
    border-width: 10px;
    width: 120px;
    height: 120px;
}

/* End of default spinner            */
/* ================================= */

/* ================================= */
/* Accordion Spinner */
/* ================================= */
.Spinner--accordion {

}

.Spinner--accordionDiv {
    background-color: var(--spinner-accordion-spinner-color);
    height: 100%;

    display: inline-block;
    animation: ani-spinner-accordion 1.2s infinite ease-in-out;
    margin-right: 2px;
}

.Spinner--accordionRect2 {
    animation-delay: -1.1s;
}

.Spinner--accordionRect3 {
    animation-delay: -1.0s;
}

.Spinner--accordionRect4 {
    animation-delay: -0.9s;
}

.Spinner--accordionRect5 {
    animation-delay: -0.8s;
}

@keyframes ani-spinner-accordion {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(0.8);
    }
}

/* Accordion spinner sizes */
.Spinner--accordion--xs {
    height: 20px;
}

.Spinner--accordion--small {
    height: 35px;
}

.Spinner--accordion--medium {
    height: 60px;
}

.Spinner--accordion--large {
    height: 120px;
}

.Spinner--accordionDiv--xs {
    width: 2px;
}

.Spinner--accordionDiv--small {
    width: 3px;
}

.Spinner--accordionDiv--medium {
    width: 4px;
}

.Spinner--accordionDiv--large {
    width: 8px;
}

@keyframes ani-spinner-default {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* End of Accordion Spinner */
/* ================================= */