.LiveChatIcon--small {
    width: 10px;
    height: 10px;
}

.LiveChatIcon--medium {
    width: 17px;
    height: 17px;
}

.LiveChatIcon--large {
    width: 36px;
    height: 36px;
}
