@import "../variables.css";

.BroadcastHistorySelectionPage {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  color: #000;
  position: relative;
  padding: 5px;
}

.BroadcastHistorySelectionPage .BoxBlock {
  /*height: 100%;*/
  margin: 0;
}

.BroadcastHistorySelectionPage .BoxBlock__contentBase {
  padding: 10px 0;
  flex: 1;
  overflow-y: auto;
}

.BroadcastHistorySelectionPage .BoxBlock--scrollable {
  padding: 0;
  position: relative;
  /*height: 100vh;*/
  height: calc(100vh - var(--app-header-height) - 2 * var(--card-margin));
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.BroadcastHistorySelectionPage .BoxBlock--scrollable::-webkit-scrollbar {
  width: 0;
}

.BroadcastHistorySelectionPage .BoxBlock--scrollable .BoxBlock__iconWrapper {
  display: none;
}

.BroadcastHistorySelectionPage .BroadcastHistorySelectionPage__sidePanel {
  display: flex;
  flex: 1 0;
  margin-right: 10px;
}

.BroadcastHistorySelectionPage .myBroadcastsList-item {
  display: flex;
  cursor: pointer;
  padding: 10px 10px;
  position: relative;
  border-bottom: solid 1px #e5e5e5;
}


.BroadcastHistorySelectionPage .myBroadcastsList-item-image img {
  display: block;
  width: 80px;
  height: 60px;
  object-fit: cover;
}

.BroadcastHistorySelectionPage img:before {
  content: ' ';
  display: block;
  position: absolute;
  width: 80px;
  height: 60px;
  background-image: url(https://ui-avatars.com/api/?name=Loola+TV&background=random);
  /*content:url(http://placekitten.com/g/250/250);*/
  /*background-image: url(https://placekitten.com/g/250/250);*/
  /*background-image: url(http://robohash.org/robot);*/
  /*background-image: url(https://avatars.dicebear.com/api/male/john.svg?mood[]=happy&mood[]=sad);*/
  /*https://www.seekpng.com/png/detail/797-7979747_80s-pikachu-acrylic-pixel-art-painting.png*/
  /*background-image: url(https://api.hello-avatar.com/adorables/80/adorable.png);*/
  /*background-image: url(https://api.adorable.io/avatars/80/adorable.png);*/
  /*background-image: url(http://www.lozano-hemmer.com/image_sets/method_random/method_random2.jpg, );*/
  /*background-image: url(http://placehold.it/200x200);*/
  /*background-image: url(../assets/icons/profile/user.svg)*/
}

.myBroadcastsList__item--selected {
  border-radius: 0 7px 7px 0;
  background-color: #e5e5e5;
  margin-right: 4px;
}

.myBroadcastsList-item-description {
  display: flex;
  padding: 0 5px;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0;
}

.myBroadcastsList-item-broadcastName {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  line-height: 1.4;
  word-break: break-all;
  word-wrap: break-word;
}

.myBroadcastsList-item-download {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 10px;
  top: 10px;
}

.myBroadcastsList-item-statistics {
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  font-size: xx-small;
  width: 90%;
}

.BroadcastHistorySelectionPage-content {
  display: flex;
  flex: 4 0;
  flex-direction: column;
  position: relative;
}

.BroadcastHistorySelectionPage-content .BoxBlock {
  position: relative;
  flex: 0 0 auto; 
  /*overflow-y: auto;*/
}

.BroadcastHistorySelectionPage-content.phrase-1-wrapper .BoxBlock {
  height: 100%;
}

.BroadcastHistorySelectionPage-content .BoxBlock .BoxBlock__titleSection {
  display: none;
}

.BroadcastHistorySelectionPage-content .BoxBlock h4 {
  margin: 0;
  color: #000;
  font-weight: 600;
  margin-bottom: 10px;
}

.BroadcastHistorySelectionPage-content .BoxBlock .BoxBlock__contentBase {
  padding: 10px;
}

.BroadcastHistorySelectionPage-content .BoxBlock:nth-child(2) {
  position: relative;
  flex: 2 0;
  margin-top: 16px;
}

.BroadcastHistorySelectionPage-broadcast-preview-titlee-1 {
  text-align: center;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.BroadcastHistorySelectionPage-phrase-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}


.BroadcastHistorySelectionPage-phrase-1 .arrow-left {
  width: 100px;
}

.BroadcastHistorySelectionPage-recently {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  width: 270px;
}

.BroadcastHistorySelectionPage-recently .BoxBlock {
  height: calc(100vh - 70px);
  margin: 5px 5px 0 5px;
  opacity: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.BroadcastHistorySelectionPage-recently .BoxBlock  .BoxBlock__iconWrapper {
  display: none;
}

.BroadcastHistorySelectionPage-recently-phrase-2 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.BroadcastHistorySelectionPage-recently-phrase-2 span {
  font-size: 30px;
  font-weight: lighter;
}

.full-statistics {
  display: flex;
  height: 100%;
  position: relative;
}

.full-statistics .full-statistics-div {
  width: 100%;
  height: 100%;
}

.full-statistics-div:nth-child(2) {
  padding-left: 40px;
}

.BroadcastHistorySelectionPage .BoxBlock__contentBase-wrap {
  height: 100%;
  overflow-y: auto;
}

.BroadcastHistorySelectionPage-content .broadcast-name {
  font-weight: bold;
}

.BroadcastHistorySelectionPage-content .BoxBlock__contentBase{
  height: 100%;
  overflow-y: auto;
}

.BroadcastHistorySelectionPage-content .summary-info {
  margin-top: 20px;
  display: flex;
}

.summary-info p {
  margin: 0px;
  line-height: 2;
  font-size: x-small;
  justify-content: space-between;
  display: flex;
  width: 125%;
  position: relative;
}

.spanP{
    font-weight: 600;
    padding-left: 3%;
    position: absolute;
    left: 100%;
    width: max-content;
}

.spanPfiftyPercent{
    font-weight: 600;
    padding-left: 3%;
    position: absolute;
    left: 100%;
    width: max-content;
}

.BroadcastHistorySelectionPage-content .summary-info .fiftyPercent {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.BoxBlock--broadcast-preview {
  border-radius: 10px;
}

.BoxBlock--broadcast-preview .BoxBlock__contentBase {
  padding: 0 !important;
}

.full-statistics-div-platforms {
  display: flex;
  margin-top: 25px;
  flex-flow: row wrap;
}

.full-statistics-div-platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  /* border: 1px solid; */
  /* width: 100px; */
}

.full-statistics-div-platform:first-child {
  margin-left: 0;
}

.full-statistics-div-platform:last-child {
  margin-right: 0;
}

.full-statistics-div-platform p {
  margin: 3px 0;
}

.full-statistics-div-platform-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
}

.full-statistics-div-platform-image-wrapper img {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
}

.broadcast-preview-image-wrapper {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #000;
}

.separator-line {
  border-right: 1px solid #eaeaea;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  top: 0;
}

.broadcast-preview-title {
  padding: 10px 30px;
  background: #fff;
  border-radius: 20px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  margin: 20px;
  position: absolute;
  z-index: 1;
}

.broadcast-missing-preview-text {
  color: #fff;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  margin: 20px;
  position: absolute;
  z-index: 1;
}

.broadcast-name-wrapper {
  flex: 1 0; 
}

.broadcast-summary-heading{
  display: flex;
  padding-bottom: 10px;
}

.broadcast-action-container{
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}
.broadcast-share-on-IGTV{
  margin-right: 25px;
}

@media only screen and (max-width: 1366px) {
  .myBroadcastsList-item-statistics {
    padding: 0;
  }

  .full-statistics-div h4 {
    margin: 0;
  }

  .summary-info {
    margin-top: 10px !important;
    font-size: 12px;
  }

  .broadcast-name-wrapper {
    line-height: normal;
  }

  .full-statistics-div-platforms {
    margin-top: 0;
  }

  .full-statistics-div-platform p {
    margin: 0;
  }

  .full-statistics-div-platform-image-wrapper {
    width: 40px;
    height: 40px;
  }

  .full-statistics-div-platform-image-wrapper img {
    width: 22px;
  }
}

@media only screen and (max-width: 1000px) {
  .summary-info {
    display: block;
  }

  .broadcast-name-wrapper {
    line-height: normal;
  }

  .full-statistics {
    flex-direction: column;
  }

  .BroadcastHistorySelectionPage {
    display: block;
    height: auto;
    padding-bottom: 0;
  }

  .BroadcastHistorySelectionPage-content .BoxBlock h4 {
    margin-bottom: 10px;
  }

  .BroadcastHistorySelectionPage .BroadcastHistorySelectionPage__sidePanel {
    margin-right: 0;
    margin-bottom: 10px;
    height: calc(50vh - 8px);
  }

  .BroadcastHistorySelectionPage .BoxBlock {
    display: block;
  }

  .broadcast-preview-image-wrapper {
    border-radius: 10px 10px 0 0;
    position: relative;
    height: auto;
  }
  
  .separator-line {
    display: none;
  }

  .full-statistics-div:nth-child(2) {
    padding: 0;
    margin-top: 20px;
  }

  .summary-info {
    flex-direction: column;
    font-size: 1em;
  }

  .BroadcastHistorySelectionPage-content .BoxBlock:nth-child(1) {
    order: 2;
  }

  .BroadcastHistorySelectionPage-content .BoxBlock:nth-child(2) {
    margin: 0 0 10px 0;
  }

  .BroadcastHistorySelectionPage-content .summary-info .fiftyPercent {
    width: 100%;
  }

  .BroadcastHistorySelectionPage-recently .BoxBlock {
    height: 100vh;
  }

  .phrase-1-wrapper {
    height: calc(50vh - ((var(--app-header-height) + 9px)));
  }

  .word-left {
    display: none;
  }

  .BroadcastHistorySelectionPage-phrase-1  {
    font-size: 14px;
    width: 70%;
  }

  .BroadcastHistorySelectionPage-phrase-1 .arrow-left {
    display: none;
  }

  .full-statistics-div-platforms {
    justify-content: center;
  }

  .BroadcastHistorySelectionPage-recently{
    top: 130%;
  }

}

@media only screen and (max-width: 420px) {
  .broadcast-preview-title {
    font-size: 12px;
    padding: 5px 15px;
    margin: 10px;
  }
}


/* overlay videoplayer */
.rh5v-DefaultPlayer_controls {
  position: absolute;
  bottom: 30px !important;
  right: 0;
  left: 0;
  height: 57px !important;
  display: -webkit-flex;
  display: flex;
  background-color: rgba(0,0,0,0.7);
  opacity: 0;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  width: 400px;
  border-radius: 30px;
  margin: 0 auto;
  align-items: center;
}

.rh5v-PlayPause_component {
  border-radius: 50%;
  background-color: white;
  margin-left: 12px;
  margin-right: 6px;
}

.rh5v-PlayPause_component:hover {
  background-color: white !important;
}

.rh5v-Volume_component {
  border-radius: 50%;
}

.rh5v-Fullscreen_component {
  border-radius: 50%;
  margin-right: 12px;
}

.rh5v-Volume_icon {
  padding: 0px !important;
}

.rh5v-Fullscreen_icon {
  padding: 0px !important;
}

.rh5v-Volume_component:hover .rh5v-Volume_slider {
  border-radius: 20px !important;
}

.rh5v-PlayPause_icon {
  padding: 0px  !important;
  fill: black !important;
}

.rh5v-Overlay_inner {
  display: none !important;
}

.rh5v-Seek_track {
  position: absolute;
  top: 50%;
  left: 5px;
  right: 5px;
  height: 2px !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #3e3e3e;
}

.rh5v-Seek_fill {
  background: #2bb9fd !important;
}

.rh5v-DefaultPlayer_component {
  background-color: transparent !important;
  font-size: 0px !important;
  height: 100% !important;
  flex: 1 0;
}

.video-wrapper{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rh5v-DefaultPlayer_video{
  border-radius: 10px;
}

.select-broadcast-height-wrapper{
    height: 100%;
    height: -moz-available;
    height: -webkit-fill-available;
}

@-moz-document url-prefix() {

  .select-broadcast-height-wrapper{
    height: 90vh; 
  }
}
