.Eye--small {
    width: 10px;
    height: 10px;
}

.Eye--medium {
    width: 22px;
    height: 15px;
}

.Eye--large {
    width: 24px;
    height: 24px;
}
