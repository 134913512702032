@media all and (max-width: 1440px) {
    .line-background {
        padding: 90px 20px;
    }
}

@media all and (max-width: 1100px) {
    .startedBox {
        left: 0;
        padding: 15px;
    }

    .workPeople {
        width: 600px;
        height: 500px;
    }

    header {
        margin-bottom: 80px;
    }

    .title {
        font-size: 30px;
    }
    .description {
        font-size: 20px;
    }
    .btnStart {
        font-size: 20px;
        width: 200px;
        height: 58px;
    }
    
    .free {
        font-size: 20px;
    }

    .slogan-bottom {
        font-size: 30px;
    }

    .slogan-top {
        font-size: 25px;
    }

    .videoText {
        font-size: 30px;
    }

    #preview-container-box {
        padding: 0 10px;
    }

    .btn-box {
        width: 200px
    }

}


@media all and (max-width: 900px) {
    header {
        padding-top: 25px;
    }

    .workPeople {
        max-width: 400px;
        width: 100%;
        height: 300px;
    }

    header {
        margin-bottom: 30px;
    }

    .slogan-bottom {
        font-size: 26px;
    }

    .slogan-top {
        font-size: 22px;
    }
}

@media all and (max-width: 800px) {
   
    .preview-box {
        position: static;
    }

    #preview-container-box {
        margin: 100px 0;
    }

    .preview-box {
        flex-direction: column;
    }

    .preview-box.rightFloat img {
        order: 1
    }

    .preview-box.rightFloat .preview-container {
        order: 2
    }

    .preview-box {
        margin: 0 auto;
        margin-bottom: 50px;
    }

    .preview-box  img {
        margin-bottom: 25px;
    }

    .preview-container {
        margin-right: auto
    }

    .preview-box.rightFloat .preview-container {
        margin-left: auto;
        margin-right: 0;
    }

    .navigation-container-box {
        flex-direction: column
    }

    .navigation-box {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .navigation-img {
        margin: 0
    }

    .navigation-box > div:nth-child(2) {
        max-width: 225px;
        width: 100%;
    }

    .navigation-container-box > .navigation-box:nth-child(2) .navigation-title {
        width: 100%;
    }

    .navigation-title {
        text-align: left;
        margin: 0;
        margin-bottom: 10px;
    }

    .line-background button {
        font-size: 20px;
    }

    .logo {
        width: 90px;
        height: 34px;
    }
}

@media all and (max-width: 680px) {
    .getStarted {
        flex-direction: column;
    }

    .responseBox {
        position: absolute;
        max-width: 360px;
        width: 100%;
        text-align: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 64%;
    }

    .btn-box {
        margin: 0 auto;
    }

    .workPeople {
        margin: 0 auto;
        margin-bottom: 50%;
    }

    .top-section {
        background-size: 200% 52%;
        background-repeat: no-repeat;
    }

    .startedBox {
        position: static;
    }

    .free, .description {
        color: #000;
    }

    .LandingPage__headerItems {
        max-width: 320px;
    }

    .LandingPage__headerItems a {
        padding: 6px 14px;
        font-size: 14px;
    }
}

@media all and (max-width: 600px) {
    
    .slogan-top {
        font-size: 22px;
        width: 50%;
        margin: 0 auto;
    }

    .slogan-bottom {
        font-size: 24px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
        width: 50%;
    }
}

@media all and (max-width: 500px) {
    .textFooter {
        flex-direction: column;
    }

    .videoClickButton{
        width:66px;
        height:66px;
    }

    #videoBox {
        padding-bottom: 56.3%;
        margin-left: 0%;
        margin-right: 0%;
    } 
}


@media all and (max-width: 450px) {
    .navigation-box {
        padding: 10px;
    }

    .navigation-link {
        width: 120px;
        font-size: 18px;
        padding: 7px 0;
    }

    .burgers {
        display: block;
    }

    .LandingPage__headerItems {
        display: none;
    }

    .navigation-img {
        width: 80px;
        height: 80px;
    }

    .navigation-img img {
        height: 40px;
        width: 40px;
    }

    .videoText {
        margin-bottom: 20px;
    }

    .navigation-container-box > .navigation-box:nth-child(2) .navigation-img img {
        width: 100%;
    }
}

@media all and (max-width: 375px) {
    .responseBox {
        position: absolute;
        max-width: 360px;
        width: 100%;
        text-align: center;
        bottom: 150px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    
}

