.PlatformCounter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  margin-right: 25px;
  margin-bottom: 10px;
  font-size: 14px;
}

.PlatformCounter__icon-wrap {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  background-color: #fff;
  border-radius: 50%;
}

.PlatformCounter__icon {
  width: 20px;
  height: 20px;
}


.PlatformsCountersList {
  margin: 8px 60px;
  padding: 18px 20px;
  align-items: center;
  color: #fff;
  font-size: var(--broadcast-summary-platform-titles-fz);
}

.PlatformCounterList__titles {
  margin-right: 30px;
  text-align: left;
  font-weight: 600;
}

.PlatformCounter__values {
  text-align: left;
}

.PlatformCounter__value {
  font-size: var(--broadcast-summary-platform-values-fz);
}


@media all and (max-width: 1000px) {
  .PlatformsCountersList {
    margin-left: 10px;
    margin-right: 10px;
  }
}
