@import "../variables.css";

.LiveVideoServerGridOverlay {
    position: absolute;
    padding: 10px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.LiveVideoServerGridOverlay__instagramLayer {
    position: relative;
    margin: 1px;
    border-width: 2px;
    /* border-style: dotted;
    border-color: #fff; */
    color: #fff;
    /* 9:16 ratio ==> 0.5625 */
    width: calc(100% * 0.5625);
    height: 96%;
    border-radius: 40px;
    border: 10px solid transparent;
    border-image: url("../assets/icons/border.svg") 30 round;
}

.LiveVideoServerGridOverlay__instagramLayer span {
    position: absolute;
    background-color: #2cbaff;
    border-radius: 10px;
    padding: 6px 14px;
    box-sizing: border-box;
    font-size: 10px;
    left: 18px;
    top: -21px;
}

.LiveVideoServerGridOverlay__watermarkLayer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
}
