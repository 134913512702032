.instagram-box {
    font-size: 10px;
    font-family: Roboto, Lato, Arial, Helvetica, Sans-Serif;
}

.instagram-box p {
    font-size: 1.6rem;
    color: #222;
}

.instagram-box {
    /*background-color: white;*/
    /*box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);*/
    width: 100%;
    height: 10rem;
    border-radius: 0.2rem;
}

.instagram-box h1 {
    font-weight: 800;
    font-size: 1.9rem;
    line-height: 0.7rem;
    margin: 0;
    color: #333;
}

.author {
    color: teal;
    font-size: 1.5rem;
    vertical-align: middle;
    font-weight: 400;
}

.instagram-box h2 {
    font-weight: 400;
    font-size: 1.5rem;
    color: #666;
}

.instagram-box a:hover {
    text-decoration: none;
    color: white;
    font-weight: 500;
}

.footer-text {
    text-align: center;
    color: #AAA;
    font-size: 1.4rem;
}

.fa-heart {
    font-size: 1.2rem;
}

.button-container {
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
}

.button-name {
    text-align: center;
    margin: 1rem 0 0.4rem 0;
    font-size: 1.6rem;
    font-weight: 600;
}

.button-class {
    text-align: center;
    margin: 0;
    font-size: 1.4rem;
    font-family: Monospace;
}



/* Facebook Login Button Styles */
/*.instagram-login {*/
    /*padding: 8px 10px;*/
    /*background-color: #4267b2;*/
    /*color: #FFFFFF;*/
    /*border-radius: 4px;*/
    /*font-family: Helvetica, Arial, sans-serif;*/
    /*letter-spacing: .25px;*/
    /*text-decoration: none;*/
    /*font-size: 1.0rem;*/
    /*transition-duration: 300ms;*/
    /*transition-timing-function: ease-in-out;*/
/*}*/

.instagram-login {
    display: block;
    padding: 8px 10px;
    border-radius: 4px;
    letter-spacing: .25px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 1.0rem;
    overflow: hidden;
    user-select: none;
    background: linear-gradient(to right, rgb(236, 146, 35) 0%, rgb(177, 42, 160) 50%, rgb(105, 14, 224) 100%);
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}


/*a:hover {*/
    /*text-decoration: none;*/
/*}*/

.instagram-login-text {
    padding: 0 14px;
    text-decoration: none;
}

.instagram-icon>svg {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
}

.instagram-login:hover {
    /*background-color: #365899;*/
    background: linear-gradient(to right, rgb(200, 146, 25) 0%, rgb(140, 42, 120) 50%, rgb(85, 14, 200) 100%);
    transition-property: background-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

.instagram-login:active {
    background: linear-gradient(to right, rgb(236, 146, 35) 0%, rgb(177, 42, 160) 50%, rgb(105, 14, 224) 100%);
    transition-duration: 40ms;
    transition-property: background-color, box-shadow;
    box-shadow: 1px 1px 2px 0px rgba(0,0,0,0.2);
}
