header {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: 150px;
    padding-top: 50px;
}

.logo {
    width: 130px;
    height: 50px;
}

.LandingPage__headerItems {
    max-width: 520px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LandingPage__headerItems a {
    text-decoration: none;
    font-family: Nunito;
    padding: 8px 20px;
    border-radius: 17.5px;
    transition: all .4s;
    font-size: 18px;
}

.LandingPage__headerItems--white a{
    color: #ffffff;
}

.LandingPage__headerItems--black a{
    color: #000000;
}

.LandingPage__headerItems a:hover {
    background-color: #20c782;
    color: #fff;
}

.burgers {
    display: none;
    z-index: 99;
}

.burger {
    display: inline-block;
    border: 0;
    background: none;
    outline: 0;
    padding: 0;
    cursor: pointer;
    border-bottom: 3px solid currentColor;
    width: 28px;
    height: 21px;
    transition: border-bottom 1s ease-in-out;
    -webkit-transition: border-bottom 1s ease-in-out;
    color: #fff;
    
}
.burger::-moz-focus-inner {
    border: 0;
    padding: 0;
}
.burger:before {
    content: "";
    display: block;
    border-bottom: 3px solid currentColor;
    width: 100%;
    margin-bottom: 6px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger:after {
    content: "";
    display: block;
    border-bottom: 3px solid currentColor;
    width: 100%;
    margin-bottom: 5px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger-check {
    display: none;
}
.burger-check:checked ~ .burger {
    border-bottom: 4px solid transparent;
    transition: border-bottom 0.8s ease-in-out;
    -webkit-transition: border-bottom 0.8s ease-in-out;
}
.burger-check:checked ~ .burger:before {
    transform: rotate(-405deg) translateY(1px) translateX(-3px);
    -webkit-transform: rotate(-405deg) translateY(1px) translateX(-3px);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.burger-check:checked ~ .burger:after {
    transform: rotate(405deg) translateY(-4px) translateX(-5px);
    -webkit-transform: rotate(405deg) translateY(-4px) translateX(-5px);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
}
.navigation {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
    position: absolute;
    right: 10px;
    z-index: 100;
}
.burger-check:checked ~ .navigation {
    max-height: 500px;
    transition: max-height 0.5s ease-in-out;
}

#navigation1 a {
    color: #fff
}

.navigation ul {
    background: #F6F6F6;
    border-radius: 8px;
    padding: 8px;
}

.navigation ul li a {
    font-size: 18px;
    color: #000 !important;
    line-height: 42px;
    z-index: 100;
    text-decoration: none;
}


