.invalid-resolution-box__wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: 100%;
    z-index: 200;
    background-color: #494949;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
}

.invalid-resolution-box__error{
  position: relative;
  display: flex;
  align-items: center;
}
.invalid-resolution-box__error-message {
  position: relative;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
}
.invalid-resolution-box__error__reason{
  margin: 16px 0;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}

.invalid-resolution-box__error__guide{
  color: #fff;
  font-weight: 700;
  font-size: 14px;
}

.invalid-resolution-box__error-message--important {
  font-size: 18px;
  color: red;
  font-weight: 600;
  line-height: 1.1;
  display: flex;
  margin-left: 6px;
}

.invalid-resolution-box__error-content::before {
  content: '';
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -200%);
  width: 64px;
  height: 64px;
  background: url('../assets/icons_new/sad.svg') no-repeat;
}

.invalid-resolution-box__upgrade-plan {
  font-size: 18px;
  font-weight: 600;
  color: #2dbaff;
  cursor: pointer;
}
