@import "../variables.css";

.SinglePlatformConfigurationPage {
    display: flex;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 3px;
    border-radius: 8px;
}

.SinglePlatformConfigurationPage__screen--allSet {
    height: 100%;
    width: 100%;
    font-size: var(--xlarge-font-size);
}

.SinglePlatformConfigurationPage__title {
    margin-top: 0;
    margin-bottom: 13px;
    color: var(--users-text-color);
    font-size: 15px;
    font-weight: 600;
    font-family: var(--basic-text-font-family);
}

.SinglePlatformConfigurationPage__content {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
}

.SinglePlatformConfigurationPage__platformImage-wrap {
    position: relative;
}

.SinglePlatformConfigurationPage__platformImage {
    width: 55px;
    height: 50px;
    margin-bottom: 15px;
}

.SinglePlatformConfigurationPage__platformImage-connected {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 0;
    right: -4px;
}

.SinglePlatformConfigurationPage__platformImage-connected img {
    width: 100%;
    height: 100%;
}

.SinglePlatformConfigurationPage__textualMessage {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 17px;
}

.SinglePlatformConfigurationPage__actionFooter {
    display: flex;
    justify-content: space-between;
}

.SinglePlatformConfigurationPage__actionButtons {
    width: 190px;
    display: flex;
    justify-content: center;
}

.SinglePlatformConfigurationPage__actionButtonsCancelButtonWrapper {
    width: 70px;
}

.SinglePlatformConfigurationPage__actionButtonsSaveButtonWrapper {
    width: 100px;
}

.SinglePlatformConfigurationPage__actionFooterDisconnectLink {
    color: var(--buttons-cancel);
    margin-top: 15px;
    background-color: #fff;
}

.SinglePlatformConfigurationPage__actionFooterDisconnectLink:hover {
    color: var(--background-error);
    cursor: pointer;
}

.SinglePlatformConfigurationPage__errorMessage {
    color: var(--background-error);
}

.TextField__inputFormItem--SinglePlatform .TextField__inputFormInputLabel {
    background-color: #fff;
}

.SinglePlatformConfigurationPage__actionFooter {
    flex-direction: column;
    align-items: center;
}

.SinglePlatformConfigurationPage__secure-wrap {
    padding-top: 12px;
}

.SinglePlatformConfigurationPage__secure {
    position: relative;
    font-size: 11px;
    font-weight: 600;
    color: var(--users-text-color);
}

.SinglePlatformConfigurationPage__secure::before {
    content: "";
    width: 8px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(-100%, -50%);
    background-image: url('../assets/icons_new/secure.svg');
}

@keyframes spinner {
    from {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(360deg)
    }
}

.SinglePlatform__Spinner {
    animation: spinner 1s ease-out infinite;
}

.SinglePlatform__Spinner-wrap {
    padding-top: 100px;
    padding-bottom: 100px;
}


.pseudo-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SinglePlatformConfigurationPage__screen--connected {
    padding-top: 65px;
    padding-bottom: 60px;
}

.SinglePlatformConfigurationPage__connected-text {
    font-weight: 600;
    font-size: 20px;
    color: var(--users-text-color);
    display: flex;
    flex-direction: column;
}
