@import "../variables.css";

.RTMPVideoPlayer {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #fff;
    color: var(--empty-messages-grey-color);
    font-size: var(--box-block-empty-fz);
}

.RTMPVideoPlayer__Title {
    padding: 8px;
    align-content: center;
}

.RTMPVideoPlayer__Content ol {
    list-style-type: decimal;
    line-height: 2;
}
.RTMPVideoPlayer__Content ol li {
    padding-left: 3px;
}

.RTMPVideoPlayer__Content {
    padding: 8px;
    align-content: center;
    margin-bottom: 20px;
}

.RTMPVideoPlayer__Note {
    padding: 8px;
    font-style: italic;
    align-content: center;
    margin-bottom: 20px;
}

.RTMPVideoPlayer__Note--black {
    color: #000000;
}
