:root {
  --menu-padding: 8px;
}


.LocalUser__broadcastSettings {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 7px;
  z-index: 1;
}

.LocalUser__broadcastSettings-item {
  background: rgba(45, 45, 45, 0.58);
  color: #fff;
  border-radius: 6px;
  min-width: 65px;
  height: 55px;
  margin: 3px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LocalUser__broadcastSettings-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LocalUser__broadcastSettings-actions svg {
  width: 100%;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
}

.LocalUser__broadcastSettings-actions svg:hover,
.LocalUser__broadcastSettings-icon:hover {
  background-color: rgba(0, 109, 240, 0.8);
}

.LocalUser__broadcastSettings-icon {
  width: 20px;
  padding: 0 4px;
  display: flex;
  border-radius: 6px;
  justify-content: center;
  cursor: pointer;
}

.LocalUser__broadcastSettings-menu {
  background-color: rgba(45, 45, 45, 0.58);
  border-radius: 6px;
  padding: 12px var(--menu-padding);
  color: #fff;
  font-size: 10px;
}

.LocalUser__broadcastSettings-menu_item {
  margin-bottom: var(--menu-padding);
  cursor: pointer;
}

.LocalUser__broadcastSettings-menu_item:last-child {
  margin-bottom: 0;
}

.LocalUser__broadcastSettings-menu_item:hover,
.LocalUser__broadcastSettings-menu_item--selected {
  color: #ffc200
}

.LocalUser__broadcastSettings-menu_item--disabled {
  pointer-events: none;
  color: gray;
}

.LocalUser__broadcastSettings-menu_divider {
  width: calc(100% + var(--menu-padding));
  height: 3px;
  background-color: rgba(255,255,255,0.3);
  margin: 10px 0 10px -4px;
}

.LocalUser__broadcastSettings-text {
  user-select: none;
}