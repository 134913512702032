.notification-modal__wrapper{
    display: flex;
    justify-content: center;
}

.modal__title > h1{
    color: var(--users-text-color);
    font-size: 18px;
    font-weight: 600;
    font-family: var(--basic-text-font-family);
}

.notification-modal__action{
    margin: 0;
}