@import "../variables.css";

.WelcomePage{
    /* color: var(--welcome-page-text-color-clean); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 100%;

    background: url(../assets/images/welkomePageBackground.svg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

/** The TEXT Section **/
.WelcomePage__welcomeMessage{
    display: flex;
    flex-direction: column;
}

.WelcomePage__company {
    text-align: center;
    margin-bottom: var(--gutter);
}

.WelcomePage__company img {
    width: 35%;
}

.WelcomePage__firstSentence{
    margin-bottom: var(--gutter);
    font-family: Nunito;
    font-size: 27px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c5c5c;
    margin: 0 auto;
    margin-bottom: 5%;
}

.WelcomePage__secondSentence{
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c5c5c;
    margin-bottom: 1%;
}

/** The Video Section **/
/* iframe.fullvideo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
} */

 /* The only rule that matters */
/* #video_box{
    float:left;
}  */

/* .WelcomePage__videoOverlays {
    background: rgba(0,0,0,0.4);
    z-index: -98;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
} */

 /*  making the video fullscreen  */
/* .WelcomePage__videoBackground {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
} */