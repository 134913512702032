@import "../variables.css";

.Button {
    cursor: pointer;
    border: none;
    /*display: flex;*/
    /*justify-content: center;*/
}

/* Themes */
.Button--default {

}

.Button--goLive {
    font-size: 11px;
    font-weight: var(--go-live-button-font-weight);
    border: 0;
    background-color: var(--go-live-button-color);
    color: #fff;
    width: 65px;
    height: 32px;
    border-radius: 100px;
}

button[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor:not-allowed
}

.Button--goOffline {
    font-size: 0.8em;
    border: 0;
    background-color: #aabbcc;
    color: #fff;
    width: 100px;
    height: 32px;
    border-radius: 100px;
}

.Button__contentWithShowLoading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Button--save {
    min-width: 90px;
    min-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background-color: var(--new-follower-indication-color);
    border-radius: 9px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    outline: none;
    align-self: flex-end;
    margin-left: auto;
    justify-self: flex-end;
}


.Button--delete {
    min-width: 90px;
    min-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background-color: var(--red-pink-color);
    border-radius: 9px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    outline: none;
}

.Button--cancel {
    text-transform: uppercase;
    background-color: var(--buttons-cancel);
    color: white;
    font-size: 12px;
    border: 0;
    width: 100%;
    outline: none;
    padding: 5px;
    font-weight: 600;
    /*height: 30px;*/
}

.Button--saved {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: saved 1s;
  width: 100%;
}


@media all and (min-width: 400px) {

    .Button--goLive {
        width: 100px;
        font-size: var(--go-live-button-font-size);
    }
}
