.PlatformsCountersList {
    display: flex;
    /* justify-content: space-between; */
    text-align: center;
    padding: 5px;
}

.PlatformCounterList__values {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
}
