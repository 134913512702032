
.TextField__inputFormItem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 12px;
}

.TextField__inputFormInputLabel {
    position: absolute;
    width: auto;
    top: 15px;
    padding-left: 4px;
    padding-right: 4px;
    background-color: #fec403;
    left: 15px;
    font-size: 13px;
    color: #858585;
    transition: all 0.2s;
    z-index: 0;
}

.TextField__inputFormInputLabel.floated {
    top: -7px;
    left: 27px;
    font-size: 11px;
    font-weight: 600;
    transform: none;
    z-index: 2;
}

.TextField__inputFormInput {
    width: 100%;
    padding: 12px 19px;
    font-size: 13px;
    color: #858585;
    border: solid 2px #f6f6f6;
    border-radius: 12px;
    z-index: 1;
    background-color: transparent;
}

.TextField__inputFormInput-errorLabel {
    padding-left: 20px;
    padding-top: 4px;
    color: #fa6969;
    font-size: 12px;
    font-weight: 600;
}

.TextField__inputFormItem--error .TextField__inputFormInput,
.TextField__inputFormItem--error .TextField__inputFormInput:focus {
    border-color: #fa6969;
}

.TextField__inputFormInput:focus {
    outline: none;
    border-color: #fec403;
}

.SinglePlatformConfigurationPage__inputFormInputEyeIcon {
    cursor: pointer;
    position: absolute;
    width: 13px;
    height: 9px;
    right: 10px;
    z-index: 5;
    top: 20px;
    background: url('../assets/icons_new/visibility-button.svg') no-repeat center center / cover;
}

.passwordWrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

.SinglePlatformConfigurationPage__HelpLink {
    margin-bottom: 15px;
    font-size: 13px;
    color: #7bd2fb;
    text-align: center;
    font-weight: 600;
}

.SinglePlatformConfigurationPage__actionFooterDisconnectLink {
    color: #d0d0d0;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
}
