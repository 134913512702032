.about__heart {
  position: relative;
  padding-top: 143px;
  padding-bottom: 10px;
  background-color: #fff;
  border-top: 1px solid #f5f5f5;
  background-image: url('../../assets/icons_new/about/heart-background.svg')
}

.about__heart-icons-wrap {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
}

.about__heart-icons {
  display: flex;
  justify-content: space-evenly;
}

.about__heart-icon-img {
  position: relative;
}

.about__heart-icon-img:nth-child(1) {
  bottom: -18px;
}

.about__heart-icon-img:nth-child(2) {
  bottom: -9px;
}

.about__heart-icon-img:nth-child(3) {
  bottom: -34px;
}

.about__heart-icon-img:nth-child(4) {
  bottom: -4px;
}

.about__heart-icon-img:nth-child(6) {
  bottom: -60px;
}

.about__heart-icon-img:nth-child(7) {
  bottom: -2px;
}

.about__title--heart {
  color: #20cb64;
}

.about__title--heart .about__title-entity {
  color: #fff;
}
.about__heart-title-leadership{
  position: relative;
  max-width: 360px;
  width: 100%;
  text-align: center;
  border-radius: 35px;
  background-color: #ffffff;
  /* box-shadow: 0 5px 4px 0 #dca900; */
  margin: 64px auto;
  padding: 13px 60px;
}
.about__heart-title-investors{
  position: relative;
  max-width: 540px;
  width: 100%;
  text-align: center;
  border-radius: 35px;
  background-color: #ffffff;
  /* box-shadow: 0 5px 4px 0 #dca900; */
  margin: 64px auto;
  padding: 13px 60px;
}
.about__heart-title-txt{
  /* width: 168px;
  height: 45px; */
  font-family: Nunito;
  font-size: 33px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
}

.about__heart-title-txt:before {
  position: absolute;
  content: '';
  height: 1px;
  background-color: #4a4a4a;
  width: 50px;
  top: 50%;
  right: 0px;
}

.about__heart-title-txt:after {
  position: absolute;
  content: '';
  height: 1px;
  background-color: #4a4a4a;
  width: 50px;
  top: 50%;
  left: 0px;
}

.about__heart-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about__heart-member {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 34px;
  padding: 0 25px;
}

.about__heart-member-foto-wrap {
  width: 143px;
  height: 143px;
  margin-bottom: 17px;
  border: 14px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 #bfbfbf;
  filter: grayscale(100%);
  transition: .5s all;
}

.about__heart-member-foto-wrap:hover {
  filter: none;
}

.about__heart-member-foto {
  border-radius: 50%;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.about__heart-member-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000;
  font-size: 16px;
  line-height: 1.44;
}

.about__heart-member-name {
  font-weight: 600;
}
.about__heart-member-role{
  width: 191px;
  height: 46px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}
.about__heart-member-social {
  margin-top: 14px;
  padding: 7px;
  background-color: #fff;
  border-radius: 6px;
}

/* remove bottom spacing */
.about__heart-member-social-img {
  display: block;
}

@media all and (max-width: 968px) {
  .about__heart {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .about__heart-member {
    width: 33.333%;
  }
}

@media all and (max-width: 750px) {
  .about__heart-icon-img:nth-child(2n) {
    display: none;
  }

  .about__heart-member {
    width: 50%;
  }
}

@media all and (max-width: 540px) {
  .about__heart-member {
    width: 100%;
  }
}
