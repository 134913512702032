.TabContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0;
    height: 100px;
}

.TabContent__body{
    display: flex;
    justify-content: center;
    flex: 1 0;
    height: 100%;
}

.TabContent__title{
    margin-top: -5px;
    margin-left: 50px;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 6px;
}

.TabContent__backAction {
    align-self: center;
    /* flex-grow: 1; */
    width: 35px;
    height: 35px;
    box-shadow: 0 4px 10px 3px rgba(146, 146, 146, 0.08);
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 60px 0px 15px;
    cursor: pointer;
    color: #fe1a69;
    font-weight: bold;
}

.TabContent__backAction span {
  transform: scaleY(2);
}

.TabContent__content--withoutBackButton{
    display: flex;
    justify-content: center;
}

.TabContent__content--withBackButton {
    flex-grow: 3;
}
