@import "../variables.css";

.LiveActionBar {
    display: flex;
    justify-content: center;
    padding-left: 0px;
    padding-right: 0px;
}

.LiveActionBar__actionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--action-bar-icon-width);
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
}

.LiveActionBar__iconWrapper{
    cursor: pointer;
}

.LiveActionBar__internalElement {
    /* pointer-events: none; */
}

.LiveActionBar__comingSoon--show {
    visibility: visible;
    animation: cssAnimation 1s ease-in 2s forwards;
}

.LiveActionBar__comingSoon--hidden {
    visibility: hidden;
}

@keyframes cssAnimation {
    0% {
        opacity: 1;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.LiveActionBar__comingSoon {
    position: absolute;
    transform: rotate(45deg);
    padding: 30px 10px;
}

.LiveActionBar__comingSoon--text {
    color: red;
    background-color: #ffffff;
    justify-content: center;
    border-top: 1px red solid;
    border-bottom: 1px red solid;
}


.LiveActionBar__iconWrapper {
    width: var(--action-bar-icon-width);
    height: var(--action-bar-icon-height);

    background-color: #ffffff;
    box-shadow: 0 5px 10px 3px rgba(254, 26, 105, 0.07);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.LiveActionBar__internalElement--music {
  box-shadow: 0 5px 10px 3px rgba(254, 26, 105, 0.07);
}

.LiveActionBar__internalElement--music .ComingSoon {
  border-color: rgba(254, 26, 105, 1);
  color: rgba(254, 26, 105, 1);
}

.LiveActionBar__internalElement--giveaways {
  box-shadow: 0 4px 10px 3px rgba(224, 77, 207, 0.1);
}

.LiveActionBar__internalElement--giveaways .ComingSoon {
  border-color: rgba(224, 77, 207, 1);
  color: rgba(224, 77, 207, 1);
}

.LiveActionBar__internalElement--layout {
  box-shadow: 0 4px 10px 3px rgba(11, 173, 251, 0.08);
}

.LiveActionBar__internalElement--layout .ComingSoon {
  border-color: rgba(11, 173, 251, 1);
  color: rgba(11, 173, 251, 1);
}

.LiveActionBar__internalElement--effects {
  box-shadow: 0 4px 10px 3px rgba(70, 222, 123, 0.1);
}

.LiveActionBar__internalElement--effects .ComingSoon {
  border-color: rgba(70, 222, 123, 1);
  color: rgba(70, 222, 123, 1);
}

.LiveActionBar__internalElement--background {
  box-shadow: 0 4px 10px 2px rgba(242, 104, 10, 0.09);
}

.LiveActionBar__internalElement--background .ComingSoon {
  border-color: rgba(242, 104, 10, 1);
  color: rgba(242, 104, 10, 1);
}

.LiveActionBar__internalElement--polls {
  box-shadow: 0 4px 10px 3px rgba(253, 199, 25, 0.1);
}

.LiveActionBar__internalElement--polls .ComingSoon {
  border-color: rgba(253, 199, 25, 1);
  color: rgba(253, 199, 25, 1);
}

.LiveActionBar__iconText {
  color: var(--action-bar-grey-color);
  font-size: var(--live-action-bar-text-fz);
}

.MainVideoRoomPage__videoRailContainer .BoxBlock__contentBase {
  padding-left: 0;
  padding-right: 0;
}

.ComingSoon--LiveActionBar {
  transform: scale(1) rotate(25deg) translate(+10px, 20px);
}

@media all and (max-width: 1400px) {
  .LiveActionBar {
    flex-wrap: wrap;
  }
}

@media all and (max-width: 1260px) {
  .LiveActionBar__actionWrapper {
    margin-right: 15px;
    margin-left: 15px;
  }

  .LiveActionBar__iconWrapper {
    width: calc(var(--action-bar-icon-width) * 0.55);
    height: calc(var(--action-bar-icon-height) * 0.55);
  }
}

@media all and (max-width: 600px) {
    .LiveActionBar__iconText {
        font-size: var(--small-font-size);
    }
}

@media all and (max-width: 400px) {
    .LiveActionBar__iconText {
        font-size: var(--xs-font-size);
    }
}
