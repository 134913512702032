@import "../variables.css";

.BroadcastSummaryHints {
    margin: 8px 60px;
    padding: 18px 20px;
    align-items: center;
    color: #fff;
    font-size: var(--broadcast-summary-platform-titles-fz);
    /*background-color: rgba(255, 255, 255, 0.05);*/
    background-color: var(--go-live-button-color);
}