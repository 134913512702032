.ChatViewerBox {
    overflow: scroll;
}

.ChatViewerBox__titleSection {
    display: flex;
    flex-direction: row;
}

.ChatViewerBox__titleSection--counter {
    align-self: center;
}

.BoxBlock--hasScroll {
  position: relative;
  overflow: hidden;
  padding-bottom: 25px;
}

.BoxBlock--hasScroll .BoxBlock__contentBase-wrap {
  overflow-y: scroll;
  height: calc(100vh - 165px);
  padding: 0;
  margin: 15px 9px 0 11px;
}
