@import "../../variables.css";

.LocalUserInformationIcon {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-end;
    margin-left: auto;
}

.LocalUserIcon {
    background: rgba(45, 45, 45, 0.58);
    border-radius: 6px;
    margin: 3px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LocalUserIcon__icon {
    color: #fff;
}

.LocalUserIcon:hover {
    background: rgba(0, 109, 240, 0.8);
}
