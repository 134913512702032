@keyframes shareProcessing {
    0% {
      background-position: -30vw 0
    }
    100% {
      background-position: 70vw 0
    }
}

.ShareOnIGTVButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    font-size: 12px;
    line-height: 12px;
    min-width: 150px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    border-radius: 9px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    margin-left: auto;
}

.ShareOnIGTVButton__icon{
    margin-left: 8px;
}
.ShareOnIGTVButton__action-text{
    margin-right: auto;
}

.ShareOnIGTVButton .Button__contentWithShowLoading{
    width: 100%;
}

.ShareOnIGTVButton .Spinner{
    margin-left: auto;
}

.ShareOnIGTVButton.loading {
    animation: shareProcessing 10s ease infinite;
    animation-timing-function: linear;
    cursor: not-allowed;
}

.ShareOnIGTVButton.success {
   background: var(--new-follower-indication-color);
   pointer-events: none;
}

