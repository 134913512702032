@import "../variables.css";

.ConfigurePlatformsPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 42px;
    font-family: var(--basic-text-font-family);
}

.ConfigurePlatformsPage__title {
    margin-top: 0;
    font: 800 21px var(--basic-text-font-family);
    color: var(--users-text-color);
}

.ConfigurePlatformsPage__platformsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.ConfigurePlatformsPage__divItemStyle {
    padding: 10px;
    text-align: center;
    margin-bottom: 32px;
    width: 100%;
}

.ConfigurePlatformsPage__Identification {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    padding:5px;
    margin-bottom: 0px;
}

.ConfigurePlatformsPage__Identification--subject {
    font-weight: bold;
    padding-right: 10px;
}

.ConfigurePlatformsPage__logout {
    width: 100%;
    position: relative;
}

.ConfigurePlatformsPage__logout-button {
    position: relative;
    display: block;
    min-width: 108px;
    min-height: 35px;
    margin: 0 auto;
    padding: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
    border-radius: 9px;
    border: none;
    background-color: #f2680a;
    cursor: pointer;
    z-index: 2;
}

.ConfigurePlatformsPage__logout::before {
    content: '';
    position: absolute;
    max-width: 333px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    background-color: #ededed;
    z-index: 1;
}

@media all and (min-width: 800px) {
    .ConfigurePlatformsPage__platformsContainer {
        flex-wrap: nowrap;
    }
    .ConfigurePlatformsPage__divItemStyle {
        /* width: 500px; */
    }
}
