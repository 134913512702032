@import "../../variables.css";

.ActiveFan {
    /* padding-bottom: 10px;
    padding-top: 10px; */
    /* border-top: 1px solid #808080; */
    margin-top: 25px;
    display: flex;
    font-family: var(--basic-text-font-family);
}

.ActiveFan:last-child {
  margin-bottom: 45px;
}

.ActiveFan__fanImage {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: var(--gutter);
}

.ActiveFan__fanData {

}

.ActiveFan__fanName {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    font-weight: bold;
    color: var(--users-text-color);
}

.ActiveFan__fanName .PlatformIcon__channelStyle {
    margin-right: 5px;
}

.ActiveFan__fanName > a {
  color: var(--users-text-color);
}

.ActiveFan__fanName > a:hover {
  text-decoration: none;
}

.ActiveFan__fanActivity {
    font-size: 13px;
    color: var(--users-text-color);
    padding-left: 17px;
}

.ActiveFan__likeIcon {
    margin-right: calc(var(--gutter) / 2);
    color: var(--like-icon-color);
}

.ActiveFan__videoCameraIcon {
    color: var(--video-camera-color);
}


.fan-data-icons{
    width: 10px;
    height: 10px;
    margin-right:5px;
}
