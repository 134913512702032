@import "../variables.css";

.PlatformItem {
    padding-top: 20px;
    cursor: pointer;
    width: 73px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 8px;
    transition: 0.2s;
    position: relative;
}

.PlatformItem__platformItemCog {
    position: absolute;
    top: 10px;
    right: 5px;
    line-height: 0.2;
}

.PlatformItem__platformItemImage {
    display: block;
    margin: 0 auto;
    max-width: 40px;
    max-height: 30px;
}

.PlatformItem__platformItemImage-wrap {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  border-radius: 9px;
  margin-bottom: 7px;
  overflow: hidden;
}

.PlatformItem__platformItemImage-inner {
  position: relative;
  width: 73px;
  height: 73px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.PlatformItem.active .PlatformItem__platformItemImage-inner::before {
  content: url('../assets/icons_new/check.svg');
  position: absolute;
  display: flex;
  bottom: 0;
  height: 11px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #46de7b;
  line-height: 0.2;
}

.PlatformItem__platformItemText {
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 11px;
    color: var(--users-text-color);
    letter-spacing: 0.5px;
    transition: 0.2s;
}
