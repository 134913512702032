@import "../variables.css";

.PresenceIndicator {
    display: flex;
    align-items: center;
    list-style-type: none;
}

.PresenceIndicator__item {
    margin-right: var(--gutter);
    font-size: var(--text-font-size);
}

.PresenceIndicator__cog {
    font-size: 20px;
}