@import "../../variables.css";

@keyframes componentVisible {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*.pricingPage {*/
    /*background-color: #2bb6f9;*/
    /*!* margin-top: -5px; *!*/
    /*font-family: var(--basic-text-font-family);*/
/*}*/

.PricingPage a:hover{
    text-decoration: none;
    cursor: pointer;
    color: #fff;
}

.PricingPage a:focus {
  text-decoration: none;
  color: #fff;
}

.PricingPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 100%;

    background: url(../../assets/images/welkomePageBackground.svg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.PricingPage span{
    cursor: pointer;
}

.PricingPage__welcomeMessage{
    display: flex;
    flex-direction: column;
}

.PricingPage__company {
    text-align: center;
    margin-bottom: var(--gutter);
    margin-top: calc(var(--gutter) * 4);
}

.PricingPage__company img {
    width: 35%;
}

.PricingPage__firstSentence{
    /* margin-bottom: var(--gutter); */
    font-family: Nunito;
    font-size: 38px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c5c5c;
    margin: 0 auto;
    margin-bottom: 10px;
}

/************************************************************************************/

.tab-list {
    text-align: center;
}

.tab-list-item {
    display: inline-block;
    width: 136px;
    /* list-style: none; */
    /* margin-bottom: -1px; */
    /* padding: 0.5rem 0.75rem; */
    padding: 10px 0;
    border-radius: 20px;
    /* color: rgb(0, 0, 0); */
    margin: 0 10px;
    text-align: center;
}

.tab-list-active {
    /* background: rgb(255, 255, 255); */
    /* border: none; */
    /* border-width: 1px 1px 0 1px; */
    padding: 10px 20px;
    border-radius: 20px;
    margin: 10px;
    color: rgb(0, 0, 0);
}

.personal{
    background: rgb(16, 190, 224);
    color:white;
    border: solid 1px rgb(16, 190, 224);
}

.business{
    background: rgb(227, 65, 129);
    color:white;
    border: solid 1px rgb(227, 65, 129);
}

.personalBorder{
    background: none;
    color: #000;
}

.personalBorder:hover{
    background: rgb(16, 190, 224);
    /* transition-duration: 0.5s; */
    color: #fff;
}

.businessBorder{
  background: none;
  color: #000;
}

.businessBorder:hover{
    background: rgb(227, 65, 129);
    /* transition-duration: 0.5s; */
    color: #fff;
}

/****************************************************************************************/

.box-wrapper {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    height: inherit;
}

.box-navigation-wrapper{
    display: block;
}

.navigation-container-box-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.navigation-box-tabs {
    width: 250px;
    border: 1px solid rgb(232, 230, 230);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    margin: 20px;
    display: inline-table;
}

.PricingPage .pricing-box-price {
    font-size: 40px;
    font-weight: bold;
    /*color: #5c5c5c;*/
    /*color: var(--golden-yellow-color);*/
    /*color: var(--red-pink-color);*/
}

.PricingPage .pricing-box-text {
    font-size: 14px;
    font-weight: bold;
    /*color: #5c5c5c;*/
    /*color: var(--golden-yellow-color);*/
    /*color: var(--red-pink-color);*/
}

.PricingPage .pricing-box-title{
    background: rgb(16, 190, 224);
    margin: 0;
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-family: Nunito;
    font-weight: normal;
    padding: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    white-space: pre-wrap;
}

.PricingPage .pricing-box-checkout{
    border-radius: 3px;
    /* background-color: #f32d8f; */
    background: rgb(16, 190, 224);
    max-width: 180px;
    width: 100%;
    color: #fff;
    padding: 12px 0;
    text-align: center;
    display: block;
    font-size: 20px;
    text-decoration: none;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
}

.PricingPage .pricing-box-checkout.disabled{
    cursor: not-allowed;
    pointer-events: none;

    border: 1px solid #999999;
    background-color: #fff;
    color: #666666;
}


.text-tabs{
    text-align: center;
    border-bottom: 1px solid #eee;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    white-space: pre-wrap;
}

.tab-content {
    display: flex;
    /* animation: componentVisible 1s; */
    /* height: 450px; */
    margin-bottom: 40px;
}

.PricingPage header {
    margin-bottom: 30px;
}

.navigation-box-tabs-middle {
    box-shadow: 0 0 10px 2px rgba(0,0,0, .2);
    transform: scale(1.08);
}

.switch{
    max-width: 210px;
    width: 100%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre;
}

.bottom-text{
    margin-top: auto;
    margin-left: 20px;
}

.footer{
    width: 360px;
    margin: 0 auto;
}