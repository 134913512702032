@import "../variables.css";

.LiveVideo {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  margin-top: 2px;

  /* Please do no change overflow without talking to Shani, */
  /* as this is doubling the CPU on Avital's PC - for real :) */
  /*overflow: hidden;*/
}

/* this will block all elements on page for interactions, except of SummaryBroadcast elements */
.LiveVideo.blockPage::before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.LiveVideo > div:first-child {
  height: 100%;
}

@media all and (min-width: 600px) {
  .LiveVideo {
    margin-top: 8px;
  }
}
