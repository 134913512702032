.LiveVideoBroadcastSummary {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 34px;
  padding-bottom: 43px;
  background-color: rgba(0, 0, 0, 0.65);
  font-family: var(--basic-text-font-family);
  border-radius: 12px;
  z-index: 15;
}

.BroadcastSummaryModal__dailyAchievement {
  padding: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BroadcastSummaryModal__broadcastSummaryCommands {
    margin-top: 42px;
}

.BroadcastSummaryHeader {
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
}

.BroadcastSummaryHeader__title {
  font-size: var(--broadcast-summary-header__title-fz);
  color: #fff;
}

.BroadcastSummaryHeader__duration {
  opacity: 0.63;
  font-size: var(--broadcast-summary-header__duration-fz);
}

.BroadcastSummaryModal__middle {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
}

.BroadcastSummaryCounters,
.BroadcastSummaryModal__dailyAchievement,
.PlatformsCountersList {
  padding: 26px 24px;
  background-color: rgba(255, 255, 255, 0.05);
}

.BroadcastSummary__inner-rounded-box {
  border-radius: 8px;
}

.BroadcastSummaryCounters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: 7px;
}


/* percents it is a width in px in psd, multiplied in 100 for convenience,
 and 600 is a width of all Broadcast block in psd minus paddings (728px - 60px sides padding
and 8 px padding between two blocks) */
.BroadcastSummaryCounters {
  width: calc(40200% / 600);
}
.BroadcastSummaryModal__dailyAchievement {
  width: calc(19800% / 600);
}

.SummaryCounter--summary-counters {
  width: 50%;
  position: relative;
  padding-left: 30px;
  text-align: left;
  color: #fff;
}

.SummaryCounter:nth-child(1n+3) {
  margin-top: 40px;
}

.SummaryCounter--summary-counters .SummaryCounter__title {
  font-size: var(--broadcast-summary-counters-title-fz);
  font-weight: 600;
}

.SummaryCounter--summary-counters .SummaryCounter__value {
  font-size: var(--broadcast-summary-counters-value-fz);
}

.SummaryCounter--summary-counters .SummaryCounter__children {
  position: absolute;
  top: 0;
  left: 0;
}

.SummaryCounter--summary-counters .SummaryCounter__children path {
  fill: rgba(255, 255, 255, 1);
}

.DailyAchievement {
  border: 0;
  padding: 0;
}

.DailyAchievement_newRecord {
  color: #fff;
  margin-bottom: 10px;
}

.SummaryCounter--daily-achievement .Like--medium {
  width: 51px;
  height: 51px;
}

.SummaryCounter--daily-achievement {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding-top: 67px;
  color: #fff;
  font-size: 16px;
}

.SummaryCounter--daily-achievement .SummaryCounter__children {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.SummaryCounter--daily-achievement .SummaryCounter__value {
  margin-right: 5px;
}

@media all and (max-width: 1000px) {
  .BroadcastSummaryModal__middle {
    padding-left: 10px;
    padding-right: 10px;
  }

  .BroadcastSummaryCounters,
  .BroadcastSummaryModal__dailyAchievement,
  .PlatformsCountersList {
    padding: 15px 10px;
  }


  .SummaryCounter--summary-counters .SummaryCounter__children {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .SummaryCounter--summary-counters {
    padding-left: 0;
    padding-top: 25px;
    text-align: center;
  }
}

@media all and (max-width: 700px) {
  .LiveVideoBroadcastSummary {
    padding-top: 14px;
    padding-bottom: 23px;
  }

  .BroadcastSummaryModal__broadcastSummaryCommands {
    margin-top: 16px;
  }
}

@media all and (max-height: 730px) {
  .LiveVideoBroadcastSummary {
    padding-top: 15px;
  }

  .BroadcastSummaryHeader {
    margin-bottom: 10px;
  }

  .BroadcastSummaryModal__broadcastSummaryCommands {
      margin-top: 12px;
  }

  .BroadcastSummaryCounters,
  .BroadcastSummaryModal__dailyAchievement,
  .PlatformsCountersList {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media all and (max-height: 619px) {
  .LiveVideo .LiveVideoBroadcastSummary {
    height: calc(100vh - 77px);
  }

  .LiveVideo.blockPage + .BoxBlock .LiveActionBar {
    display: none;
  }
}
