
.about__mind {
  position: relative;
  padding-bottom: 183px;
  background-color: #fff;
}

.about__mind .about__container {
  position: relative;
}

.about__mind:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
  padding-top: 20%;
  background-image: url('../../assets/icons_new/about/mind-clouds.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.about__title--mind {
  position: relative;
  color: #ff0067;
}

.about__title--mind:before {
  content: '';
  position: absolute;
  top: -45px;
  left: calc( (var(--about-container-side-padding) - 20px) * -1);
  width: 115px;
  height: 115px;
  background-image: url('../../assets/icons_new/about/mind-title-icon.svg');
  left: 8px;
}

.about__title--mind .about__title-entity {
  color: #fff;
}

.about__mind .about__text {
  color: #525050;
  font-size: 24px;
}

.about__mind-link {
  color: #fe1969;
}

.about__mind-link:hover {
  color: #fe1969;
}

.about__mind-icon {
  position: absolute;
}

.about__mind-icon.top-right {
  /* top: -210px; */
  bottom: calc( 100% - 30px);
  right: 20px;
}

.about__mind-icon.middle-left {
  left: 20px;
  top: 78%;
}

.about__mind-icon.middle-right {
  right: 20px;
  bottom: calc( 100% - 150px);
}

.about__mind-icon.bottom-middle {
  bottom: -65px;
  left: 36%;
}
