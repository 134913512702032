
.cls-1 {
  fill: #eceef1;
}

.cls-2 {
  fill: #4488ce;
  filter: url(#gradient-overlay-1);
  fill-rule: evenodd;
}

.st0{ fill: white; }