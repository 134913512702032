@import 'variables.css';

.aboutPage {
  background-color: #2bb6f9;
  /* margin-top: -5px; */
  font-family: var(--basic-text-font-family);
}

.about__container {
  max-width: 1181px;
  width: 100%;
  margin: 0 auto;
  padding-left: var(--about-container-side-padding);
  padding-right: var(--about-container-side-padding);
}

.about__title {
  margin-bottom: 42px;
  font-size: 48px;
  font-weight: 800;
}

.about__title-entity {
  position: relative;
  z-index: 2;
  padding-left: 8px;
  padding-right: 18px;
  padding-bottom: 9px;
  left: -1%;
}

.about__title-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  z-index: -1;
}

.about__text_soul {
  position: relative;
  font-size: 26px;
  color: #fff;
  line-height: 1.23;
  width: 96%;
}

.about__text_mind {
    position: relative;
    font-size: 24px;
    font-weight: bold;
    color: #525050;
    line-height: 1.33;
    width: 98%;
}

@media all and (max-width: 650px) {
  .about__container {
    padding-left: calc( var(--about-container-side-padding) / 2);
    padding-right: calc( var(--about-container-side-padding) / 2);
  }
}
