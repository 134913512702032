@import "../../variables.css";

.UpgradeVersionIndicator__remainingMessage {
    color: red;
}

.UpgradeVersionIndicator__upgradeMessage {
    color: blue;
}

.BroadcastSummaryHints__hint .UpgradeVersionIndicator__upgradeMessage
{
    color: var(--yellow-button-color);
}

.BroadcastSummaryHints__hint a:hover {
    color: var(--yellow-button-color);
    background-color: transparent;
    text-decoration: underline;
}
