@import "../../variables.css";

/*p {*/
    /*margin: 0*/
/*}*/

.VideoLayoutSettingsTab{
    background-color: #ffffff;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;;
    flex: 1;
}

.VideoLayoutSettingsTab__titleSetting {
    font-family: Nunito;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 6px;
}

.VideoLayoutSettingsTab__topBox{
    display: flex;
    align-items: center;
}

/*
*
*   checkbox 
*
*/

.container {
    display: flex;
    align-items: center;
    position: relative;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkMark {
    position: absolute;
    left: -30px;
    top: 15px;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 2px;
    border: solid 1px #bfbfbf;
    z-index: 1;
}

/* On mouse-over, add a white background color */
.container:hover input ~ .checkMark {
    background-color: #fff;
}

/* When the checkbox is checked, add a white background */
.container input:checked ~ .checkMark {
    background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkMark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkMark:after {
    left: 4px;
    width: 5px;
    height: 10px;
    border: solid 1px rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/****************************/

.yourVideoTitleChoice{
    width: 160px;
    height: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 6px 1px rgba(11, 173, 251, 0.08);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 28px;
    position: relative;
    outline: none;
}

.yourVideoTitleChoiceInput{
    font-family: Nunito;
    font-size: 11px;
    color: #656565;
    position: absolute;
    left: 10px;
    outline: none;
    border: none;
    width: 90%;
}

/****************************/

.yourLogoChoice{
    width: 190px; 
    height: 25px;
    border-radius: 5px;
    box-shadow: 0 2px 6px 1px rgba(11, 173, 251, 0.08);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    position: relative;
    outline: none;
}

.inputFileBox {
    display: flex;
    justify-content: center;
    text-align: center;
    display: flex;
    flex: auto;
    left: 7px;
    outline: none;
    border: none;
    width: 95%;
}
  
.inputFile {
    display: none;
}
  
.fileBox {
    position: absolute;
    left: 0;
    padding-left: 10px;
    font-size: 11px;
    top: 5px;
    text-align: left;
    overflow: hidden;
    height: 70%;
    width: 90%;
    color: #656565;
    pointer-events: none;
 }
  
.fileButton {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 38px;
    height: 20px;
    background-color: #2bb6f9;
    border-radius: 6px;
    border: none;
    font-size: 8px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fileButton:hover{
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.5);
}

.fileButton:active{
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.5);
}

.VideoLayoutSettingsTab__bottomBox{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -15px;
}

.VideoLayoutSettingsTab__backgroundColorBox{
    display: flex;
    max-width: 280px;
    width: 100%;
    margin-top: 15px;
    box-sizing: border-box;
    /* justify-content: space-between; */
}

.VideoLayoutSettingsTab__backgroundTitle{
    font-family: Nunito;
    font-size: 12px;
    font-weight: bold;
    color: #5e5e5e;
}

.VideoLayoutSettingsTab__backgroundColorContainerBox{
    /* max-width: 150px; */
    width: 100%;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-between;
    position: relative;
    background: #fff;
}

.TextField__inputFormItem--VideoLayout {
    width: auto;
    margin-bottom: 6px;
    margin-right: 6px;
}

.TextField__inputFormItem--VideoLayout .TextField__inputFormInputLabel {
    background-color: #fff;
}

.btnApply {
    width: 156px;
    height: 43px;
    border-radius: 9px;
    background-color: #2bb6f9;
    font-family: Nunito;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.btnGolive-box{
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
}

.btnGolive {
    width: 384px;
    height: 56px;
    border-radius: 9px;
    background-color: #1ecd5a;
    font-family: Nunito;
    font-size: 17px;
    font-weight: bold;
    font-style: normal;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.colorPickerWrapper {
  position: relative;
}

.ColorPickerButton {
  width: 40px;
  height: 15px;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  outline: none;
  border: 1px solid #fff;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 65px;
  transform: translate(0, -50%);
  background: #fff;
  padding: 6px 10px;
  z-index: 1;
  filter: drop-shadow(0 0 1px rgba(0,0,0,.2));
  display: flex;
  width: 180px;
  border-radius: 5px;
}
.tooltip:after, .tooltip:before {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tooltip:after {
	border-color: rgba(136, 183, 213, 0);
	border-right-color: #fff;
	border-width: 5px;
	margin-top: -5px;
}
.tooltip:before {
	border-color: rgba(194, 225, 245, 0);
	border-right-color: #fff;
	border-width: 8px;
	margin-top: -8px;
}
