
.LoolaPermanentMessage {
  border-bottom: 0;
  box-shadow: 0 -1px 3px #eaf0f6;
  position: fixed;
  background: #fff;
  height: auto;
  top: 0;
  bottom: auto;
  left: 0;
  width: 100%;
  z-index: 100000000 !important;
  border-bottom: 1px solid #cbd6e2;
  border-top: 1px solid #cbd6e2;
  color: #33475b;
  font-family: inherit;
  font-weight: normal !important;
  text-align: left;
  text-shadow: none !important;
  font-size: 18px;
  line-height: 18px;
}

.LoolaPermanentMessage__contentSection {
  background: #fff;
  margin: 0 auto;
  max-width: 1000px;
  padding: 20px;
}

.LoolaPermanentMessage__actionSection {
  margin: 10px 0 0 !important;
  text-align: right !important;
}

.LoolaPermanentMessage #dismiss-button {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
  margin-right: 12px !important;
  color: #fff;
  font-family: inherit;
  font-size: inherit;
  font-weight: normal !important;
  line-height: inherit;
  text-align: left;
  text-shadow: none !important;
  cursor: pointer;
}

.LoolaPermanentMessage #dismiss-button {
  border-radius: 3px;
  display: inline-block;
  padding: 10px 16px !important;
  text-decoration: none !important;
}
