/* @import "../../variables.css"; */

.TermsPage a:hover{
    text-decoration: none;
    cursor: pointer;
    color: #2fa4e7;
}

.TermsPage a:focus {
  text-decoration: none;
  color: #2fa4e7;
}

.TermsPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 100%; 

    background: url(../../assets/images/welkomePageBackground.svg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.termsText{
    max-width: 600px;
    width: 100%;
    position: relative;
    padding: 5px;
}

.termsText--header{
    position: absolute;
    top: -25px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: bold;
}

.fontStrong{
    font-weight: bold; 
}

.textUnderline{
    text-decoration: underline;
}

