.BroadcastSummaryClose {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: rgba(216, 216, 216, 0.12);
  border-radius: 6px;
  cursor: pointer;
}

.BroadcastSummaryClose-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 25px;
  transform: rotate(-45deg);
}
