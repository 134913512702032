.DailyAchievement {
    display: inline-block;
    border: 1px dashed black;
    border-radius: 5px;
    padding: 10px 30px;
    text-align: center;
}

.DailyAchievement_newRecord {
    font-size: var(--broadcast-summary-daily--title-fz);
    font-weight: 600;
}
