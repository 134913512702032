@import "../variables.css";

.BoxBlock {
    /* for empty content */
    position: relative;
    min-width: 190px;
    display: flex;
    flex-direction: column;
    background-color: var(--box-base-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    margin: 8px 5px;
}

.BoxBlock__withLimitedWidth {
    max-width: 300px;
}

.BoxBlock__expandVertical {
    overflow-y: hidden;
    flex: 1;
}

.BoxBlock__withScrolling {
    overflow-y: auto;
}

/* bottom border */
.BoxBlock__border--yellow {
    border-bottom: 2px solid var(--golden-yellow-color);
}

.BoxBlock__border--green {
    border-bottom: 2px solid var(--weird-green-color);}

.BoxBlock__border--blue {
    border-bottom: 2px solid #2ab5f8;
}

.BoxBlock__border--redpink {
    border-bottom: 2px solid var(--red-pink-color);
}

/* Titles */
.BoxBlock__titleSection {
    height: 54px;
    position: relative;
}

.BoxBlock__title {
    font-size: var(--text-font-size);
    padding: 5px 14px;
    height: 54px;
    opacity: 0.9;
    clip-path: polygon(0% 0%,100% 0%,100% 60%,0% 100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative
}

.BoxBlock__iconWrapper {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #ffffff;
    position:absolute;
    top: 14px;
    right: 14px;

}

.BoxBlock__iconWrapper--green {
    box-shadow: 0 4px 10px 2px rgba(70, 222, 123, 0.16);
}

.BoxBlock__iconWrapper--yellow {
    box-shadow: 0 4px 10px 2px rgba(253, 199, 25, 0.17);
}

.BoxBlock__iconWrapper--blue {
    box-shadow: 0 4px 10px 2px rgba(11, 173, 251, 0.15);
}

.BoxBlock__title > H4{
    color: var(--box-base-color);
}

.BoxBlock__title--yellow {
    background-image: linear-gradient(257deg, #fec400, #fcb900);
}

.BoxBlock__title--green {
    background: linear-gradient(74deg, #1ccd5a, #0ae055);
}

.BoxBlock__title--blue {
    background-image: linear-gradient(259deg, #2cbaff, #06acfc);
}

.BoxBlock__title--redpink {
    background-image: linear-gradient(259deg, #2cbaff, #06acfc);
}

/* Buttons (UP and DOWN arrows) */
.BoxBlock__visibilityButton {
    position: absolute;
    top: 16px;
    display: inline-block;
    margin-left: 5px;
    background: rgba(0, 0, 0, 0.11);
    border-radius: 20%;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

i {
    border: solid white;
    border-radius: 15%;
    border-width: 0 2px 2px 0;
    margin-left: 6px;
    display: inline-block;
    padding: 3px;
  }

.BoxBlock__visibilityButton--up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin-top: 8px;
}

.BoxBlock__visibilityButton--down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 4px;
}

/* Content Base */
.BoxBlock__contentBase {
    /* padding: 5px 14px; */
}

.BoxBlock__contentBase--empty {
    position: absolute;
     /* 54px is a height of title */
    top: calc(50% + 54px);
    left: 50%;
    /* 27px is a half of a title's height */
    transform: translate(-50%, calc(-50% - 27px));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BoxBlock__emptyContentMessage {
    width: 124px;
    margin: 11px;
    text-align: center;
    color: var(--empty-messages-grey-color);
    font-size: var(--box-block-empty-fz);
}
