
@keyframes floatingButton {
  from {
    top: 0;
  }

  to {
    top: 70px;
  }
}

.SystemMessage--floating {
  animation-name: floatingButton;
  animation-duration: 1s;
}

.SystemMessage-wrap {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  z-index: 1000;
}

@keyframes errorButtonHiding {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.SystemMessage--error {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--red-pink-color);
    width: auto;
    height: auto;
    min-height: calc(var(--system-message-height) * 0.75);
    padding: 19px 17px;
    font-size: 16px;
    box-shadow: 0 4px 11px 5px rgba(0, 0, 0, 0.21);
    border-radius: 27px;
    animation-name: errorButtonHiding;
    animation-delay: 9s;
    animation-duration: 1.1s;
    z-index: 2;
}

.SystemMessage--error-text {
  margin-right: 28px;
}

.SystemMessage--error-button {
  display: flex;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 25px;
  color: var(--red-pink-color);
  background-color: #fff;
  border: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
}

@media(max-width: 600px) {
  .SystemMessage--error {
    font-size: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .SystemMessage--error-text {
    margin-right: 15px;
  }

  .SystemMessage--error-button {
    transform: rotate(-45deg) scale(0.8);
  }
}
