.BroadcastSummaryCommands {
  display: flex;
  justify-content: center;
}

.BroadcastSummaryCommands__buttonPlaceHolder {
  padding: 0 8px;
}

.BroadcastSummaryCommands__buttonPlaceHolder .Button {
  padding: 6px 18px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  font-family: var(--basic-text-font-family);
  font-size: var(--broadcast-summary-commands-buttons-fz);
  font-weight: var(--broadcast-summary-commands-buttons-fw);
}

.ComingSoon--BroadcastSummaryCommands {
  transform: rotate(10deg) translateY(5px);
}

@media all and (max-width: 850px) {
  .BroadcastSummaryCommands__buttonPlaceHolder .Button {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media all and (max-width: 450px) {
  .BroadcastSummaryCommands__buttonPlaceHolder .Button {
    font-size: 10px;
  }
}
