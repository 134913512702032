.StreamDestinationItem__actionContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
}

.StreamDestinationItem__imageContainer {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
}

.StreamDestinationItem__imageStyle {
    width: 24px;
    height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

/*.StreamDestinationItem__imageStyle[title="Youtube"] {*/
/*  width: 22px;*/
/*  height: 16px;*/
/*}*/

/*.StreamDestinationItem__imageStyle[title="Instagram"] {*/
/*  width: 20px;*/
/*  height: 20px;*/
/*}*/

.StreamDestinationItem__channelOff {
    filter: grayscale(100%);
}

.StreamDestinationItem__actionText {
    display: flex;
    justify-content: center;
    font-size: 10px;
}

@media all and (max-width: 600px) {
    .StreamDestinationItem__actionContainer {
        padding: 2px;
    }
}
