.container-slider {
  animation: fadeIn 500ms ease-in-out forwards;
  animation-duration: 4s;
  animation-delay: 2s;
  margin: 20% 0;
  background-size: cover;
  background-position: center;
  padding: 10% 0;
  background-color: #fafafa;
  position: relative;
}

.selectors-box{
  max-width: 440px;
  width: 100%;
  animation: fadeIn 2000ms ease-in-out forwards;
  right: -56%;
  display: inline-block;
  position: relative;
}

.selectors-image-header {
  font-family: Nunito;
  font-size: 33px;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 30%;
}

.selectors-image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.panel-img-wrap {
  width: 111px;
  height: 111px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 #bfbfbf;
  filter: grayscale(100%);
  transition: .5s all;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.panel-foto-img {
  border-radius: 50%;
  height: 130%;
  width: 130%;
  cursor: pointer;
  object-fit: cover;
}

.panel-info-img {
  margin: 0 0 25px;
  display: flex;
  justify-content: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #4a4a4a;
  cursor: pointer;
}

.carousel__indicator-image .panel-img-wrap:hover {
  filter: grayscale(100%);
}
.carousel__indicator-image--active .panel-img-wrap, .carousel__indicator-image--active .panel-img-wrap:hover {
  filter: grayscale(0%);
}

.selectors {
  display: flex;
  justify-content: center;
  margin-top: 30%;
  margin-bottom: 3%;
}

.carousel__indicator {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  background: #d8d8d8;
  cursor: pointer;
  opacity: 0.65;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
  border-radius: 50%;
  margin: 0 10px;
}

.carousel__indicator:hover {
  background: #f42b8b;
  opacity: 0.7;
}
.carousel__indicator--active, .carousel__indicator--active:hover {
  background: #f42b8b;
  opacity: 0.9;
}

.selector-count {
  font-family: Nunito;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
}

.panels{
  position: relative;
}

.oval-box{
  position: relative;
}

.oval-red{
  position: absolute;
  left: -30%;
  width: 80%;
  height: 80%;
  opacity: 0.14;
  background-color: #f62682;
  border-radius: 50%;
  z-index: -1;
}

.oval-yellow{
  position: absolute;
  top: 10%;
  right: 8%;
  width: 12%;
  height: 12%;
  opacity: 0.14;
  background-color: #febf00;
  border-radius: 50%;
  z-index: -1;
}

.oval-green{
  position: absolute;
  bottom: 5%;
  right: 0;
  width: 50%;
  height: 50%;
  opacity: 0.14;
  background-color: #2fce83;
  border-radius: 50%;
  z-index: -1;
}

.panel {
  position: absolute;
  max-width: 500px;
  height: 100%;
  left: 15%;
  color: #fff;
  transition: background 500ms ease-in-out;
  margin: 0 auto;
  justify-content: flex-end;
  display: none;
}

.panel img {
  height: 500px;
  object-fit: scale-down;
  display: flex;
  margin: 0 auto;
}

.slider-text {
  max-width: 447px;
  width: 100%;
  margin: 0 auto;
}

.panel h2.panel-header {
  font-size: 30px;
  font-weight: bold;
  color: #f42b8b
}

.panel p.panel-info {
  color: #4a4a4a;
  font-size: 16px;
}

.carousel__slide--active {
  display: block;
}


@media all and (max-width: 1366px){
  /* Stephanie Liu */
  .container-slider .panels > .panel:nth-child(1) img{
    top: -300px;
    left: 8%;
    width: 100%;
    height: 433px;
  }
  /* Olivia Thai */
  .container-slider .panels > .panel:nth-child(2) img{
    top: -300px;
    left: 1%;
    width: 90%;
    height: 433px;
  }
  /* Rocky G */
  .container-slider .panels > .panel:nth-child(3) img{
    top: -300px;
    left: -1%;
    width: 92%;
    height: 433px;
  }
  /* Anesha Collins */
  .container-slider .panels > .panel:nth-child(4) img{
    top: -300px;
    left: 3%;
    width: 75%;
    height: 433px;
  }

  .oval-red{
    left: -15%;
    width: 72%;
    height: 80%;
  }
  
  .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 11%;
  }
  
  .oval-green{
    bottom: 5%;
    right: 0;
    width: 43%;
    height: 50%;
  }

  .selectors-box {
    max-width: 31%;
  }

  .panel {
    left: 10%;
  }

}

@media all and (max-width: 800px) {
  .selectors-box {
    max-width: 35%;
  }

  .panel {
    left: 0%;
  }
}


@media all and (max-width: 700px) {


  .selectors-box{
    right: -86%;
  }

  .selectors-box {
    max-width: 13%;
  }

  .panel-img-wrap {
    width: 60px;
    height: 60px;
  }


  .slider-text {
    max-width: 265px;
  }

   .selectors-image-header{
    display: none;
  }

  .selector-count{
    display: none;
  }

  .selectors {
    display: none;
  }

  .panels {
    top: -113px;
    left: -13%;
  }

  .panel {
    left: 21%;
  }

  .panel h2.panel-header{
    font-size: 23px;
  } 
}

@media all and (max-width: 500px) {

  .selectors-box{
    right: -82%;
  }

  .container-slider .panels > .panel:nth-child(1) img {
    width: 67%;
    height: 433px;
  }

  .container-slider .panels > .panel:nth-child(2) img {
    width: 83%;
    height: 324px;
  }

  .container-slider .panels > .panel:nth-child(3) img {
    width: 81%;
    height: 308px;
  }

  .container-slider .panels > .panel:nth-child(4) img {
    width: 70%;
    height: 325px;
  }

  .container-slider .panels > .panel:nth-child(1) .oval-box .oval-red{
    left: -3%;
    width: 63%;
    height: 68%;
  }

  .container-slider .panels > .panel:nth-child(1) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 12%;
    height: 11%;
  }

  .container-slider .panels > .panel:nth-child(1) .oval-box .oval-green{
    bottom: 5%;
    right: 0;
    width: 48%;
    height: 50%;
  }

  .oval-red{
    left: 4%;
    width: 55%;
    height: 80%;
  }
  
  .oval-yellow{
    top: 10%;
    right: 8%;
    width: 8%;
    height: 11%;
  }
  
  .oval-green{
    bottom: 5%;
    right: 0;
    width: 37%;
    height: 50%;
  }

  .panels {
    -moz-margin-start: 16%;
  }

  .panel {
    left: 3%;
  }

  .panel-img-wrap {
    width: 40px;
    height: 40px;
  }

  .panel h2.panel-header {
    font-size: 29px;
}

  .slider-text > .panel-info:nth-child(2){
    visibility: hidden;
  }

  .slider-text > .panel-info:nth-child(4){
    visibility: hidden;
  }

  #preview-container-box > .preview-box:nth-child(3) .preview-container .box-title {
    color: #2bb8eb;
  }

  .navigation-box > div:nth-child(2) {
    max-width: 200px;
  }
}


@media all and (max-width: 375px) {

  .container-slider .panels > .panel:nth-child(1) img {
    width: 75%;
    height: 426px;
  }

  .container-slider .panels > .panel:nth-child(2) img {
    width: 83%;
    height: 324px;
  }

  .container-slider .panels > .panel:nth-child(3) img {
    width: 91%;
    height: 308px;
  }

  .container-slider .panels > .panel:nth-child(4) img {
    width: 79%;
    height: 325px
  }

  .container-slider .panels > .panel:nth-child(2) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 11%;
  }

  .container-slider .panels > .panel:nth-child(3) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 11%;
  }

  .container-slider .panels > .panel:nth-child(4) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 11%;
  }

  .panel-info-img {
    margin: 0 0 41px;
    font-size: 12px;
  }
}


@media all and (max-width: 320px) {

  .container-slider .panels > .panel:nth-child(1) img {
    width: 75%;
    height: 363px;
  }

  .container-slider .panels > .panel:nth-child(2) img {
    width: 76%;
    height: 224px;
  }

  .container-slider .panels > .panel:nth-child(3) img {
    width: 78%;
    height: 224px;
  }

  .container-slider .panels > .panel:nth-child(4) img {
    width: 79%;
    height: 276px;
  }

  .container-slider .panels > .panel:nth-child(2) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 13%;
  }

  .container-slider .panels > .panel:nth-child(3) .oval-box .oval-yellow{
    top: 10%;
    right: 8%;
    width: 10%;
    height: 13%;
  }

  .slider-text {
    max-width: 225px;
  }

  .panel-info-img {
    margin: 0 0 41px;
    font-size: 12px;
  }
}