@import "../../../src/variables.css";

.TabContent__content--withBackButton {
  margin-bottom: -20px;
}

.question-block-wrapper p {
  margin: 0;
}

.PollsSettingsTab-wrapper {
  height: 95%;
  border: 1px solid #d3d3d3 !important;
  width: 95% !important;
  padding-right: 8px;
  border-radius: 20px 20px 0 0;
  background-color: transparent;
  overflow: hidden;
  border-bottom: none !important;
}

.PollsSettingsTab {
  overflow-y: auto;
  border-bottom: none;
  padding-right: 7px;
  height: 100%;
  position: relative;
}

.PollsSettingsTab::-webkit-scrollbar {
  width: 3px;
}

.PollsSettingsTab::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-top: 20px;
}

.PollsSettingsTab::-webkit-scrollbar-thumb {
  background: #888;
}

.PollsSettingsTab::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.question-block-wrapper {
  display: flex;
  align-items: center !important;
  width: 99%;
  cursor: pointer;
  padding: 8px;
}

.question-block-wrapper:last-child {
  margin: 0;
}

.question-block-wrapper .question-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
  margin-right: 5px;
}

.question-block {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.question-block .question-line {
  position: absolute;
  width: calc(100% + 8px);
  border-bottom: 1px solid #d3d3d3;
  left: 0;
  bottom: -13px;
}

.question-block-wrapper .question-name {
  margin-right: 11px;
  color: var(--users-text-color);
  font-weight: bold;
}

.question-block-wrapper .question {
  color: var(--users-text-color);
}

.question-block-wrapper .question-hider {
  margin-left: 40px;
  color: #d3d3d3;
}

.deleteQuestion {
  display: flex;
  align-items: center;
  margin-left: 40px;
  color: var(--golden-yellow-color);
  z-index: 9999;
}

.question-phrase-reply {
  margin-left: auto !important;
  color: #fff;
  font-weight: bold;
}

.question-focused {
  background: #2ebafe; 
  box-shadow: 0 4px 5px 0px rgba(0,0,0,.3);
}

.question-text-focused {
  color: #fff !important;
}

.unFocused {
  opacity: 0;
}

.hide-all-button {
  position: absolute;
  right: 75px;
  top: 10px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.sad {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
}

.sad img {
  width: 40px;
  height: 40px;
}

.sad * {
  margin: 3px auto;
}

.PollsSettingsTab-SpinnerWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
