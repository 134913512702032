@import "../../variables.css";

.GoogleSignInBox {
    display : flex;
    flex-direction:column;
    justify-content: center;
    font-size: large;
}
   
.GoogleSignInBox__buttonArea {
    display:flex;
    justify-content: center;
}

.GoogleSignInBox__googleMessage {
    display:flex;
    justify-content: center;
    font-family: Nunito;
    font-size: 14px;
    font-style: italic;
    color: #5c5c5c;
    margin-bottom: 1%;

}

.GoogleSignInBox__errorMessage {
    text-align: center;
    color:red;
}
