.VideoHLSWrapper {
    /*width: 100%;*/
    /*height: 100%;*/
}

.VideoHLSWrapper__message {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: red;
    padding: 10px;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.VideoHLSWrapper-size {
    width: 100%;
    height: 100%;
}