@import "../variables.css";

.LandscapeLayout {
  z-index: 2000;
  position: absolute;
  background-color: black;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

@media all and (orientation: landscape) {
  .LandscapeLayout {
    visibility: visible;
  }
}
