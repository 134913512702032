
.Button--download {
    min-width: 90px;
    min-height: 40px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    background-color: var(--new-follower-indication-color);
    border-radius: 9px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    outline: none;
    align-self: flex-end;
    margin-left: auto;
    justify-self: flex-end;
}

.Button--download__Icon{
    margin-left: 8px;
}
