.SampleVideoPlayerPage {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 5px;
}

.SampleVideoPlayerPage__title {
    margin: 5px;
    align-items: center;
}

.SampleVideoPlayerPage__inputSection {
    display: flex;
    flex-flow: row;
    padding: 5px;
    width: 500px;
}

.SampleVideoPlayerPage__videos {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    justify-items: center;
    border: 1px red dashed;
}

.SampleVideoPlayerPage__box {
    margin: 5px;
}