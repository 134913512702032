@keyframes blinking-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.LiveButton__liveIndicator {
    font-size: 7px;
    color: #ff4837;
    animation: blinking-animation 2s infinite;
    flex: 1;
}


.LiveButton__buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LiveButton__text {
    flex: 2;
}

.LiveButton__filler {
    flex: 1;
}