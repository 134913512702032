@import "../../variables.css";

.SubscriptionCompletedPage {
  height: 100%;
  background: url(../../assets/images/welkomePageBackground.svg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SubscriptionCompletedPage h3 {
  color: #000;
  font-weight: bold;
  margin-bottom: 20px;
}

.SubscriptionCompletedPage p {
  margin-bottom: 20px;
}

.SubscriptionCompletedPage p:nth-child(3) {
  color: var(--users-text-color);
}

.SubscriptionCompletedPage .thank_you {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.getStartedThankYou {
  width: 183px;
  height: 53px;
  border-radius: 7px;
  background: var(--new-follower-indication-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  margin: auto;
}

.getStartedThankYou:hover {
  color: #fff;
  text-decoration: none;
}
