.about__soul {
  position: relative;
  background-color: #2bb6f9;
  padding-bottom: calc(420 / 1440 * 100%);
}

.about__title--soul {
  color: #fff;
}

.about__title--soul .about__title-entity {
  color: #2bb6f9;
}

.about__soul-text-cloud {
  position: absolute;
}

.about__soul-text-cloud.left {
  left: -26px;
  bottom: -116px;
}

.about__soul-text-cloud.middle {
  right: 224px;
  bottom: -60px;
}

.about__soul-text-cloud.right {
  right: -30px;
  bottom: 15px;
}

.about__soul-bottom-cloud {
  position: absolute;
}

.about__soul-bottom-cloud.left {
  width: calc(524 / 1440 * 100%);
  left: 0;
  bottom: -30px;
}

.about__soul-bottom-cloud.middle {
  width: calc(502 / 1440 * 100%);
  left: calc(570 / 1440 * 100%);
  bottom: -20px;
}

.about__soul-bottom-cloud.right {
  width: calc(460 / 1440 * 100%);
  right: 0;
  bottom: 0;
}

@media all and (max-width: 968px) {
  .about__soul {
    padding-top: 50px;
  }
}
