@import "../../variables.css";


.ColorBoxItem{
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: space-around;
  border: none;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.ColorBoxItem--active{
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
}

.ColorBoxItem__color--blue {
  background-image: linear-gradient(to bottom, #2bb6f9 50%, #249cd8 50%);
}

.ColorBoxItem__color--green {
  background-image: linear-gradient(to bottom, #b4ec51 50%, #4b982d 50%);
}

.ColorBoxItem__color--red {
  background-image: linear-gradient(to bottom, #ff4c54 50%, #c80000 50%);
}

.ColorBoxItem__color--purple {
  background-image: linear-gradient(to bottom, #e531f1 50%, #af00bf 50%);
}

.ColorBoxItem__color--orange {
  background-image: linear-gradient(to bottom, #ffaf22 50%, #ff9000 50%);
}

.ColorBoxItem__color--cyan {
  background-image: linear-gradient(to bottom, #00b2b5 50%, #00868b 50%);
}

.ColorBoxItem__color--white {
  background-image: linear-gradient(to bottom, #ffffff  50%, #f6f6f6 50%);
}

.ColorBoxItem__color--black {
  background-image: linear-gradient(to bottom, #000000 50%, #111111 50%);
}

