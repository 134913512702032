@import "../../variables.css";

.LocalUser-wrap {
  height: 100%;
  background-color: #fff;
  padding: 55px 10px 20px;
  position: relative;
  z-index: 7;
}

.LocalUser {
  height: 100%;
    /*position: relative;*/
}

.LocalUser__videoActions {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px;
    z-index: 1;
}

.LocalUser__errorMessageWrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    z-index: 200;
    background-color: #494949;
    border-radius: 12px;

    /* Flex Trick to Make sure content is being rendered in the center and middle */
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    z-index: 6;
}
.LocalUser__errorMessageText {
  position: relative;
  padding: 75px 35px 0;
  margin-bottom: 8px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  white-space: pre-line;
}

.LocalUser__errorMessageText::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.LocalUser__errorMessageTutorialVideo {
    max-width: 500px;
    align-self: center;
    border-radius: 4px;
}

.LocalUser__errorMessageText.errorMessage--other::before {
  width: 64px;
  height: 64px;
  background: url('../../assets/icons_new/sad.svg') no-repeat;
}

.LocalUser__errorMessageText.errorMessage--video::before {
  width: 60px;
  height: 40px;
  background: url('../../assets/icons_new/shape.svg') no-repeat;
}

.LocalUser__errorMessageText.errorMessage--audio::before {
  width: 28px;
  height: 40px;
  background: url('../../assets/icons_new/fill-5.svg') no-repeat;
}

.LocalUser__errorMessageLink {
  font-size: 12px;
  font-weight: 600;
  color: #2dbaff;
  font-style: italic;
}

.LocalUser__errorMessageButton-wrap {
  margin-top: 26px;
}

.LocalUser__errorMessageButton {
  min-width: 73px;
  min-height: 25px;
  padding: 4px 12px;
  font-size: 11px;
  font-weight: 600;
  color: var(--yellow-button-color);
  border-radius: 13px;
  border: solid 1px var(--yellow-button-color);
  background-color: transparent;
  cursor: pointer;
}

.LocalUser__videoWrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.LocalUser__spinnerWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.LocalUser__video {
    height: 100%;
    width: initial;
    position: absolute;
    top: 50%;
    left: 50%;
}

.LocalUser__video[data-disabled="true"] {
    height: 0;
}

.LocalUser__video--regularOrientation {
    transform: translateY(-50%) translateX(-50%) rotateY(180deg);
}

.LocalUser__video--flipOrientation {
    transform: translateY(-50%) translateX(-50%) rotateY(0deg);
}


@media all and (min-width: 800px) {
    .LocalUser__video {
        /*height: initial;*/
        width: 100%;
    }
}
